import { Container } from "react-bootstrap";
import modulePdf from '../../pdfs/ModuleOptions.pdf';

const FreeConsultationScreen = () => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);

    return (
        <Container className="pt-5">
            <h2>Free Consultation</h2>
            <hr className="mb-5"/>

            <p>
                AcLangEDGE offers a <span className="fw-bold">free, no obligation 30-minute consultation</span> for all prospective students, agents, and schools.
                During this consultation, we will discuss your needs and goals, and how AcLangEDGE can help you achieve them.
                <br/><br/>

                To arrange your free consultation, please follow the below steps:
            </p>

            <ol>
                <li>Register for an AcLangEDGE account by clicking 'Register', and then filling out the form.</li>
                <li>Once you have registered, check your email for a confirmation email, and click on the link we sent you to verify your email address.</li>
                <li>Once you have verified your email, login to your account by clicking 'Login'.</li>
                <li>Once logged in, click 'Account' and select 'My Consultation/Lesson Bookings' to access the AcLangEDGE booking portal.</li>
                <li>Select 'AcLangEDGE Free Consultation' from the dropdown menu, and select a date and time for your consultation. Check the details of the booking.</li>
                <li>Click 'Book' to confirm your booking.</li>
                <li>You will then be emailed a live meeting link, which you can test prior to the scheduled meeting.</li>
            </ol>

            <br/>
            <p>
                * <u>For students/families</u>, please feel free to browse the modules offered below prior to your free consultation.
            </p>
            
            { !isMobileDevice && <embed src={modulePdf} type="application/pdf" height="600px"/> }
        </Container>
    )
}

export default FreeConsultationScreen;