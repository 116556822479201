import React, { useEffect } from 'react';

import { Container, Row, Col, Image } from "react-bootstrap";
import whalePhoto from '../../resources/Whale.jpg';

const AgentScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className="pt-5">
            <h2>For Agents</h2>
            <hr/>
            <Row>
                <Col xs={12} md={8}>
                    <p>Give your inbound students the edge in academic language prior to or post arrival.</p>

                    <p>AcLangEDGE 5 hour modules can be tailored for year levels, subjects and units of work the students will encounter, which can be flexibly scheduled and ideally completed prior to the students' arrival at their new school, reducing student stress and anxiety upon arrival in the first few months in classes at their new school.</p>

                    <p>Students can also sign up for flexibly scheduled 5 hour modules while enrolled in their new school, in subjects and topics they are having difficulty accessing.</p>

                    <p>AcLangEDGE can also tailor language preparation courses for your inbound study tours prior to arrival, so as to maximise the students' experiences while in Australia.</p>

                    <p>Commission is payable by AcLangEDGE to referring agents for all initial student referrals.</p>

                    <p>If your agency is interested in giving your recruited students the academic edge, please get in touch.</p>
                </Col>
                <Col xs={12} md={4}>
                    <Image src={whalePhoto} fluid />
                </Col>
            </Row>
        </Container>
    )
}

export default AgentScreen;