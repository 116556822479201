import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Form, Container } from "react-bootstrap";

const OrgLoginScreen = ({ login, isLoggedIn }) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if ( isLoggedIn ) {
      navigate("/")
    }

    window.scrollTo(0, 0);
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    login(username, password);
  };

  return (
    <Container className="pt-5">
      <Form onSubmit={handleSubmit}>
        <h3>Organisation Login</h3>
        <hr className="mb-5"/>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Username:</Form.Label>
          <Form.Control required placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} tabIndex={1}/>
        </Form.Group>
        <Form.Group className="mb-5" controlId="formPassword">
          <Form.Label>Password:<Link className="mx-3" to="/org-forgot-password">Forgot Password?</Link></Form.Label>
          <Form.Control type="password" required placeholder="Enter Password" autoComplete="true" value={password} onChange={(e) => setPassword(e.target.value)} tabIndex={2}/>
        </Form.Group>
        <Button variant="primary" type="submit" tabIndex={3}>
          Login
        </Button>

        <Form.Text className="mx-3" muted>
          Don't have an account? See our professional development courses for schools and groups <Link to="/professional-development" tabIndex={4}>here</Link>
        </Form.Text>
      </Form>
    </Container>
  );
};

export default OrgLoginScreen;
