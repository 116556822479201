import React from 'react';
import { Accordion, Button } from 'react-bootstrap';

import instance from "../../../services/api";

const Course = ({ title, subModules }) => {

    const handleDownload = async ( fileName ) => {
        const response = await instance.get(`/docs/AcLangEDGE_Micro-Module_${fileName}_Task_Sheet.docx`, { responseType: 'blob' });

        // Create a blob object from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = response.headers['content-disposition'].split('"')[1]; // Extract filename
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    return (
        <Accordion defaultActiveKey="0" className="pt-5">
            <h4 className="fw-bold">{title}</h4>
            {subModules.map((subModule, index) => (
                <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{subModule.title}</Accordion.Header>
                    <Accordion.Body>
                        <iframe title={index} src={subModule.slidesLink} width="100%" height="880" allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                        { subModule.docLink && <iframe title={index} src={subModule.docLink} width="100%" height="800"></iframe> }
                        { subModule.fileName && <Button variant="primary" className="mt-2" onClick={() => handleDownload(subModule.fileName)} download>Download</Button> }
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default Course;