import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import logo from '../../resources/ACLangLogoMain.png';

const Header = ({ isLoggedIn, isAdmin, isOrg, logout }) => {
  return (
    <Navbar sticky="top" bg="light" expand="lg">
      <Navbar.Brand href="/" className="ps-5">
        <Image src={logo} alt="Company Logo" width={164} height={64} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/bio">Bio</Nav.Link>
          <Nav.Link href="/testimonials">Testimonials</Nav.Link>
          <Nav.Link href="/professional-development">Professional Development</Nav.Link>
          <Nav.Link href="/agents">For Agents</Nav.Link>
          <Nav.Link href="/faq">FAQ</Nav.Link>
          <Nav.Link href="/free-consultation" style={{ color: "#0892D0" }}>Free Consultation</Nav.Link>
        </Nav>
        <Nav className="ms-auto pe-3">
        { isLoggedIn ?
            (<NavDropdown title="Account" id="basic-nav-dropdown" align="end">
                { isOrg ? 
                    (<NavDropdown.Item href="org-courses">Courses</NavDropdown.Item>)
                    :
                    (<>{ isAdmin ? 
                        (<div>
                            <NavDropdown.Item href="admin">Admin</NavDropdown.Item>
                            <NavDropdown.Item href="org-courses">Courses</NavDropdown.Item>
                        </div>)
                        : 
                        (<div>
                            <NavDropdown.Item href="my-account">My Account</NavDropdown.Item>
                            <NavDropdown.Item href="my-modules">My Modules</NavDropdown.Item>
                            <NavDropdown.Item href="my-bookings">My Consultation/Lesson Bookings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="my-lessons">My Upcoming Lessons</NavDropdown.Item>
                        </div>)
                    }</>)
                }
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>)
            : 
            (<>
            <Navbar.Text style={{ color: "#0892D0" }}>Login - </Navbar.Text>
            <Nav.Link href="/login">Personal</Nav.Link>
            <Navbar.Text>|</Navbar.Text>
            <Nav.Link href="/org-login">Organisation</Nav.Link>
            </>)
        }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
