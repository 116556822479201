import React, { useState, useEffect } from "react";
import { Container, Card, Button, Col } from "react-bootstrap";

import instance from "../../../services/api";

const LessonCard = ({ lesson, onCancel }) => {
  const date = new Date(lesson.date.split(":")[0] + ":" + lesson.date.split(":")[1]);
  const oneHourLater = new Date(date.getTime() + (1*60*60*1000));
  return (
    <Card className="my-2">
      <Card.Body>
        <Card.Title className="fw-bold">{lesson.moduleName}</Card.Title>
        <Card.Text className="fw-bold text-muted">
          <span className="ms-3">{date.toDateString()}</span> 
          <span className="ms-5">{date.toLocaleTimeString()}-{oneHourLater.toLocaleTimeString()} AEST (UTC+10)</span>
        </Card.Text>

        { date > new Date(new Date().getTime()+(1*24*60*60*1000)) &&
          <Button variant="primary" className="float-end" onClick={() => onCancel(lesson)}>Cancel</Button>
        }
      </Card.Body>
    </Card>
  );
};
  

const UserLessonsScreen = () => {
    const [lessons, setLessons] = useState([]);

    const cancelHandler = async ( lesson ) => {
      if ( !window.confirm("Are you sure you want to cancel this lesson?") ) return;

      if ( lesson.moduleName === "AcLangEDGE Free Consultation" ) {
        await instance.delete("/booking/free-consultation/" + lesson.id).then((response) => {
          if ( response.status !== 200 ) {
            window.alert("Failed to cancel free consultation!");
          } else {
            setLessons(lessons.filter((item) => item.id !== lesson.id));
            window.alert("Free consultation cancelled!");
          }
          
        });
        return;
      } else {
        await instance.delete("/booking/" + lesson.id).then((response) => {
          if ( response.status !== 200 ) {
            window.alert("Failed to cancel lesson!");
          } else {
            setLessons(lessons.filter((item) => item.id !== lesson.id));
            window.alert("Lesson cancelled!");
          }
        });
      }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    
        async function fetchData() {
          await instance.get("/booking")
            .then(( response ) => {
              setLessons(response.data.sort((a,b) => new Date(a.date) - new Date(b.date)));
            })
            .catch(( err ) => {
              console.error(err);
            })
        }
    
        fetchData();
      }, []);

    return (
        <Container className="pt-5">
          <h3>My Upcoming Lessons</h3>
          <hr className="mb-5"/>
          { lessons.map((lesson, index) => (
            <Col key={index} xs={12}>
              <LessonCard lesson={lesson} onCancel={cancelHandler} />
            </Col>
          )) }
        </Container>
    );
}

export default UserLessonsScreen;