import { useState, useEffect } from "react";
import axios from "axios";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      "/auth/forgot-password",
      { email },
      config
    ).then(
      () => {
        window.alert("Password reset email sent. Please check your inbox.");
      }
    ).catch(
      () => {
        window.alert("Password reset failed.");
      }
    )
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={forgotPasswordHandler}>
        <h3>Forgot Password</h3>
        <hr className="mb-5"/>
        <Form.Group className="mb-5" controlId="formEmail">
          <Form.Text muted>
            Please enter the email address you registered your account with. We
            will send your password reset email there.
          </Form.Text>
          <br/><br/>
          <Form.Label>Email:</Form.Label>
          <Form.Control type="email" required placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} tabIndex={1}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Send Email
        </Button>
      </Form>
    </Container>
  );
};

export default ForgotPasswordScreen;
