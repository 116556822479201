import React, {useEffect} from 'react';
import { Container, Image, Row, Col } from "react-bootstrap";
import gapPhoto from '../../resources/BridgingGap.png';
import wherebyPhoto from "../../resources/whereby.jpg";
import quizletPhoto from "../../resources/quizlet.jpg";
import readTheoryPhoto from "../../resources/readTheory.png";
import edPuzzlePhoto from "../../resources/edPuzzle.png";
import studentHandPhoto from "../../resources/StudentHand.jpg";

const AboutScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])  

    return (
        <Container className="pt-5">
            <h2 className="text-primary">IELTS gets you a visa to study, AcLangEDGE gets you ready to study.</h2>
            <h2 className="pt-5">Why does AcLangEDGE work?</h2>

            <Row className="pt-5">
                <Col>
                    <p>
                        Recent research conducted during several home-based learning periods in an international school setting (including daily live lessons) shows that 
                        EAL students aged 11-16 experienced <span className="text-decoration-underline">accelerated academic language acquisition rates up to 20%</span>, compared to face to face learning across the same period 
                        of time in preceding years. Supporting factors include:
                    </p>

                    <ol>
                        <li>The <span className="fw-bold">visual nature</span> of live lesson online learning when delivered by an experienced teacher trained in applied linguistics;</li>
                        <li><span className="fw-bold">No distractions</span> in online learning that exist in traditional face-to-face classroom settings - anyone who has been at school knows what these are;</li>
                        <li>The need for <span className="fw-bold">student self advocacy</span> and asking questions if unsure of instructions or tasks in the live online lesson domain, as once the lesson is over, the student must work independently on work set;</li>
                        <li>The <span className="fw-bold">linguistically non-threatening environment</span> in a live online class one-on-one with the teacher means the student is more willing to take risks and experiment with language (ask and answer questions) which they would not necessarily be willing to do in a face-to-face class containing many English first language peers. Student/Teacher rapport = maximum engagement = academic success.</li>
                    </ol>
                </Col>
                <Col md={{span: '3'}}>
                    <Image src={studentHandPhoto} fluid/>
                </Col>
            </Row>

            <p>
                AcLangEDGE lessons are <span className="fw-bold">based on the subjects and units actually being/to be studied by your child</span> at their destination or current school based on 33 years of teaching experience in the secondary Australian, British IGCSE & USA curriculum, and the teacher's academic language acquisition qualifications. 
                (Sadly many parents pay for tutors who do not use materials related to the school curriculum, because the tutors chosen are neither experienced language teachers or experienced in secondary school subject curriculum). 
                AcLangEDGE provides a unique online learning experience centred around live one-on-one tailored academic language learning lessons to help your child based on the subjects and units actually being/to be studied by your child <span className="fw-bold">'bridge the academic English language gap'</span>.
            </p>

            <Image src={gapPhoto} fluid/>

            <h2 className="pt-5">AcLangEDGE Online Learning Resources</h2>

            <p>
                AcLangEDGE uses online resources which are easily accessible (without using a VPN), and they are free for use by AcLangEDGE students. Some of the resources
                ACLangEDGE uses are listed below. All your child needs is a laptop or desktop computer with a camera, internet access, headphones with microphone, and an email address.
            </p>

            <Row>
                <Col className="d-flex justify-content-center align-items-center" xs={12} md={6}>
                    <h3>Live Lesson Hosting - <span className="fw-bold">Whereby</span></h3>
                </Col>
                <Col xs={12} md={6}>
                    <Image src={wherebyPhoto} fluid width="100%"></Image>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center align-items-center" xs={12} md={6}>
                    <h3>Academic Vocabulary Learning - <span className="fw-bold">Quizlet</span></h3>
                </Col>
                <Col xs={12} md={6}>
                    <Image src={quizletPhoto} fluid width="100%"></Image>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center align-items-center" xs={12} md={6}>
                    <h3>Academic Reading - <span className="fw-bold">Readtheory</span></h3>
                </Col>
                <Col xs={12} md={6}>
                    <Image src={readTheoryPhoto} fluid width="100%"></Image>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center align-items-center" xs={12} md={6}>
                    <h3>Viewing and Listening - <span className="fw-bold">EdPuzzle</span></h3>
                </Col>
                <Col xs={12} md={6}>
                    <Image src={edPuzzlePhoto} fluid width="100%"></Image>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutScreen;