import React, { useState, useEffect } from 'react';
import { Container } from "react-bootstrap";

import axios from "axios";

const VerifyEmailScreen = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');

    const config = {
        header: {
          "Content-Type": "application/json",
        },
    };
  
    axios.post(
        "/auth/verify-email/" + token,
        config
    ).then(
        ( response ) => {
            if ( response.status === 200 ) {
                setMessage("Email address successfully verified! You may now login.");
            } else {
                setMessage("Invalid or expired token");
            }
        }
    ).catch(
        ( error ) => {
            console.log(error);
            setMessage("Internal server error");
        }
    )
  }, []);

  return (
    <Container className="pt-5">
      <p>{message}</p>
    </Container>
  );
}

export default VerifyEmailScreen;
