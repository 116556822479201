import React, { useEffect, useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const OrgRegisterScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [isGroup, setIsGroup] = useState(false);
    const [ctFullName, setCtFullName] = useState('');
    const [ctPosition, setCtPosition] = useState('');
    const [ctPhone, setCtPhone] = useState('');
    const [ctEmail, setCtEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [orgAddress, setOrgAddress] = useState('');
    const [orgSchools, setOrgSchools] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [tcsChecked, setTcsChecked] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( password !== confirmPassword ) {
            setPassword("");
            setConfirmPassword("");
            window.alert("Passwords do not match.");
            return;
        }

        if ( password.length < 6 ) {
            setPassword("");
            setConfirmPassword("");
            window.alert("Password must be at least 6 characters long.");
            return;
        }

        navigate('/org-checkout', {
            state: {
                username,
                password,
                isGroup,
                ctFullName,
                ctPosition,
                ctPhone,
                ctEmail,
                orgName,
                orgAddress,
                orgSchools,
            }
        });
    }
    
    return (
        <Container className="mt-5">
        <Form onSubmit={handleSubmit}>
            <h3>Enter Organisation Details</h3>
            <hr/>
            <p className="mb-5 fst-italic">
                We require these details to register the organisation's account upon payment success. Since we only attempt to create your account after payment, ensure that all 
                details, particularly the contact email address, have been entered correctly.
            </p>

            <div className="fw-bold">
                Registration Steps
                <ol className="mt-3">
                    <li>Complete the registration form below, and click "Confirm Details and Pay"</li>
                    <li>Enter your payment details, and click "Pay Now"</li>
                    <li>Once the payment is successful, we will send you an email asking for verification. Click on the verification link.</li>
                    <li>Once verified, you will be able to login and start using the platform.</li>
                </ol>
            </div>
            <Form.Group className="mb-3 mt-5" controlId="formUsername">
                <Form.Label>Account Username:</Form.Label>
                <Form.Control required placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} tabIndex={1}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control type="password" required autoComplete="true" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} tabIndex={2}/>
            </Form.Group>
            <Form.Group className="mb-5" controlId="formConfirmPassword">
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control type="password" required autoComplete="true" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} tabIndex={3}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroup">
                <Form.Check type="checkbox" label="Multiple schools?" checked={isGroup} onChange={(e) => setIsGroup(e.target.checked)} tabIndex={4}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCtFullName">
                <Form.Label>Contact Full Name:</Form.Label>
                <Form.Control required placeholder="Contact Full Name" value={ctFullName} onChange={(e) => setCtFullName(e.target.value)} tabIndex={5}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCtPosition">
                <Form.Label>Contact Position:</Form.Label>
                <Form.Control required placeholder="Contact Position" value={ctPosition} onChange={(e) => setCtPosition(e.target.value)} tabIndex={6}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCtPhone">
                <Form.Label>Contact Phone:</Form.Label>
                <Form.Control required placeholder="Contact Phone" value={ctPhone} onChange={(e) => setCtPhone(e.target.value)} tabIndex={7}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCtEmail">
                <Form.Label>Contact Email:</Form.Label>
                <Form.Control type="email" required placeholder="Contact Email" value={ctEmail} onChange={(e) => setCtEmail(e.target.value)} tabIndex={8}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formOrgName">
                <Form.Label>Organisation Name:</Form.Label>
                <Form.Control required placeholder="Organisation Name" value={orgName} onChange={(e) => setOrgName(e.target.value)} tabIndex={9}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formOrgAddress">
                <Form.Label>Organisation Address:</Form.Label>
                <Form.Control required placeholder="Organisation Address" value={orgAddress} onChange={(e) => setOrgAddress(e.target.value)} tabIndex={10}/>
            </Form.Group>
            <Form.Group className="mb-5" controlId="formOrgSchools">
                <Form.Label>If registering for multiple schools, please list them below:</Form.Label>
                <Form.Control placeholder="Schools (e.g. School1, School2, School3)" value={orgSchools} onChange={(e) => setOrgSchools(e.target.value)} tabIndex={11}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTermsAndConditions">
                <Form.Check type="checkbox" label={
                <span>
                    I agree to the{' '}
                    <Link to="/org-terms-and-conditions" target="_blank">Terms and Conditions</Link>
                </span>} checked={tcsChecked} onChange={(e) => setTcsChecked(e.target.checked)} tabIndex={12}/>
            </Form.Group>
            <Button variant="primary" type="submit" tabIndex={13} disabled={!tcsChecked}>Confirm Details and Pay</Button>
        </Form>
        </Container>
    );
}

export default OrgRegisterScreen;