import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrgCheckoutForm from "./OrgCheckoutForm";

import axios from "axios";

import { config } from "../../common/Constants";

const stripePromise = loadStripe(config.stripePublishableKey);

const OrgCheckoutScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState("");
    let dataSuccess = false;
    
    let username;
    let password;
    let isGroup;
    let ctFullName;
    let ctPosition;
    let ctPhone;
    let ctEmail;
    let orgName;
    let orgAddress;
    let orgSchools;

    try {
        username = location.state.username;
        password = location.state.password;
        isGroup = location.state.isGroup;
        ctFullName = location.state.ctFullName;
        ctPosition = location.state.ctPosition;
        ctPhone = location.state.ctPhone;
        ctEmail = location.state.ctEmail;
        orgName = location.state.orgName;
        orgAddress = location.state.orgAddress;
        orgSchools = location.state.orgSchools;

        dataSuccess = true;
    } catch ( error ) {}

    useEffect(() => {
        window.scrollTo(0, 0);

        const headerConfig = {
            header: {
              "Content-Type": "application/json",
            },
        };

        if ( dataSuccess === false ) {
            navigate("/org-register");
        }

        async function fetchData() {
            // Create PaymentIntent as soon as the page loads
            await axios.post(`${config.apiBaseUrl}/payment/org-create-payment-intent`, {
                username,
                password,
                isGroup,
                ctFullName,
                ctPosition,
                ctPhone,
                ctEmail,
                orgName,
                orgAddress,
                orgSchools,
            }, headerConfig).then((response) => {
                setClientSecret(response.data.clientSecret)
            });
        }

        fetchData();
    }, [username, password, isGroup, ctFullName, ctPosition, ctPhone, ctEmail, orgName, orgAddress, orgSchools, dataSuccess, navigate]);
    
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <Container className="mt-5">
            {clientSecret && (
                <div>
                    <h3>Checkout</h3>
                    <hr/>
                    <br/><br/>
                    <Row>
                        <Col xs={12} md={6}>
                            <h4>Course</h4>
                            <hr/>
                            <br/>
                            <p className="fw-bold">AclangEDGE Vocabulary to Discourse</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <h4>Price</h4>
                            <hr/>
                            <br/>
                            { isGroup ? <p className="fw-bold">$15000.00 AUD</p> : <p className="fw-bold">$9000.00 AUD</p> }
                        </Col>
                    </Row>

                    <br/><br/>
                    Please enter your payment method and click 'Pay' to complete your purchase.
                    <br/><br/>
                    <Elements options={options} stripe={stripePromise}>
                        <OrgCheckoutForm/>
                    </Elements>
                </div>
            )}
        </Container>
    );
}

export default OrgCheckoutScreen;