import React, {useEffect} from 'react';
import { Container, Accordion } from "react-bootstrap";

const FAQ = ({ id, question, answer }) => {
    return (
        <Accordion.Item eventKey={id}>
            <Accordion.Header>
                {question}
            </Accordion.Header>
            <Accordion.Body>
                {answer}
            </Accordion.Body>
        </Accordion.Item>
    );
};

const faqs = [
  {
    question: "What is the AcLangEDGE free consultation all about?",
    answer: "Before engaging in AcLangEDGE services, of course you and your child will want to meet your AcLangEDGE online live lesson teacher, and ask questions about AcLangEDGE and the courses. This also serves as an opportunity for AcLangEDGE to assess the current academic language level of your child, so we can develop appropriate learning objectives for your child to meet, should you decide to sign your child up for an AcLangEDGE live module. This free, no-obligation, consultation runs for 30 minutes. Please note you and your child must be present at this consultation meeting.",
  },
  {
    question: "Can I as a parent attend my child's AcLangEDGE lessons as an observer? How do I know if my child is completing work and making progress?",
    answer: "AcLangEDGE strongly encourages parents to drop into lessons from time to time as it suits you. Effective learning is a three way commitment between the student, teacher and parents. You will receive weekly feedback on your child's progress from the teacher.",
  },
  {
    question: "How do I sign my child up for an AcLangEDGE module?",
    answer: 'You can sign up for any number of modules (5 x 60 minute live lessons) with AcLangEDGE across a timeframe to suit you. Upon payment you will be able to book the times (we suggest weekly) you wish your child to have those lessons, anytime from AEST 8.00am-10.00pm (Monday-Saturday). These times can be changed with 24 hours notice, simply go to "My Consultation/Lesson Bookings" once you have logged in to alter your lesson booking times.'
  },
  {
    question: "I thought scoring an entrance IELTS score or equivalent meant my child is ready for English medium of instruction schooling?",
    answer: "These well known tests are worldwide, academic English skill tests applied to the masses across all ages from lower secondary through to university level in order for students to obtain a visa to study in Australia and elsewhere. There are two versions of IELTS (General and Academic). Year 8 students sit the same academic IELTS test as post graduate university students. AcLangEDGE, based on 33 years of secondary classroom teaching experience, will actually teach the real academic language your child will see and need to know in the secondary classroom specific to the core subjects (Maths, English, Science, Geography, History, Commerce, Music and Visual Art) all students study in the Australian Curriculum context. So a Year 8 AcLangEDGE student will acquire the academic language Year 8 students see in Year 8 classrooms in Australia, UK or USA. AcLangEDGE gives your child a significant advantage upon arrival at school in Australia or an English medium of instruction international school."
  },
  {
    question: "Are AcLangEDGE live lessons recorded?",
    answer: "This is your choice. AcLangEDGE is happy to record key live lesson segments for your child's later retrieval and reviewing. These would only ever be shared with you, and cannot be used commercially or in the public domain without your prior written consent in accordance with Australian Privacy Laws."
  }
];


const FAQScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])  

    return (
        <Container className="pt-5">
            <h2>Frequently Asked Questions</h2>
            <Accordion className="pt-5" defaultActiveKey="0" alwaysOpen>
                {faqs.map((faq, index) => (
                    <FAQ key={index} id={index} question={faq.question} answer={faq.answer} />
                ))}
            </Accordion>
        </Container>
    );
};

export default FAQScreen;