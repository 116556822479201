import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Container, Row, Col, Spinner, Button } from "react-bootstrap";

import instance from "../../../services/api";

const ModuleCard = ({ module, onSignUp }) => {
  return (
    <Card className="my-2">
      <Card.Body>
        <Card.Title>{module.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          Year Levels: {module.yearLevelMin}-{module.yearLevelMax}
        </Card.Subtitle>
        { module.availableLessons === -1 && (
          <Button variant="primary" className="float-end" onClick={() => onSignUp(module)}>Sign Up</Button>
        )}

        { module.availableLessons > 0 && (
          <Card.Text className="fw-bold text-muted text-end">In Progress</Card.Text>
        )}

        { module.availableLessons === 0 && (
          <Card.Text className="fw-bold text-muted text-end">Completed</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

const UserModulesScreen = () => {
  const navigate = useNavigate();

  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      await instance.get("/module")
        .then(( response ) => {
          setModules(response.data);
          setLoading(false);
        })
        .catch(( err ) => {
          console.error(err);
          setLoading(false);
        });
    }

    fetchData();  
  }, []);

  const signUpHandler = async ( module ) => {
    navigate('/checkout', {
      state: {
        module: module,
      }
    });
  }

  const SubjectTemplate = ({ subjectName }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="mb-5">
        <Row>
          <Col xs={11}>
            <h4 className="me-2 fw-bold" >{subjectName}</h4>
          </Col>
          <Col>
            <Button onClick ={() => setIsExpanded(!isExpanded)}>
              { isExpanded ? '▲' : '▼'}
            </Button>
          </Col>
        </Row>
        
        { isExpanded && (
          <Row className="mt-5">
            { loading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              modules.map((module, index) => (
                module.subject === subjectName && 
                <Col key={index} xs={12}>
                  <ModuleCard module={module} onSignUp={signUpHandler} />
                </Col>
              ))
            )}
          </Row>
        )}
      </div>
    )
  }

  return (
    <Container className="pt-5">
      <h3>My Modules</h3>
      <hr className="mb-5"/>
      <div>
        <p>
          <span className="fw-bold">IMPORTANT:</span> Please note that you will not be able to cancel any modules once you have signed up and paid for them. For this reason, please 
          do not sign up for any modules until you have <span className="fw-bold">completed</span> your free consultation.
        </p>
        <br/><br/>
        <SubjectTemplate subjectName="Mathematics"/>
        <SubjectTemplate subjectName="Science"/>
        <SubjectTemplate subjectName="Technologies"/>
        <SubjectTemplate subjectName="HASS (Humanities and Social Studies)"/>
        <SubjectTemplate subjectName="English (EAL/D)"/>
        <SubjectTemplate subjectName="The Arts"/> 
      </div>
    </Container>
  );
};

export default UserModulesScreen;
