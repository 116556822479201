import React, {useEffect} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import wherebyPhoto from "../../resources/Lesson.jpg";

const TestimonialsScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const testimonials = [
      {
        id: 1,
        quote: 'My English skills definitely improved in the topics I studied online, such as rural-urban migration in Geography. Lesson length of 50-60 minutes was just right for my concentration levels. The online live lessons are easy to join on the ACLangEDGE platform, which did not require me to have a Google account or a VPN when I was in P.R China. The independent homework tasks set by the teacher related to each lesson were not too difficult, and having the same teacher for all my classes was really convenient. I definitely feel more confident in using English in face to face classes at my Australian school now because of the experience I had learning English in one on one online live lessons. Everything was fine, and Mr Hudson is a great teacher.',
        source: 'Amy - Yr 8 (China)',
        result: 'Upon arrival at her Australian Curriculum school, within 8 weeks Amy (Chinese Mother Tongue) was moved off the EAL program into the mainstream for all subjects including English Literature.'
      },
      {
        id: 2,
        quote: 'We are very satisfied with the online courses you offer, our daughter is very lucky to have online classes before she attended her English speaking Australian school. Your online course has excellent quality teaching and we really appreciated the careful feedback and daily home work communication. We would highly recommend this online class to other families, our daughter said she would have had difficulties in adjusting when she joins on-campus classes if she had not done her online academic language studies with you.',
        source: 'Sanghae - (Father of Yr 9 student Sumin - Korean)',
        result: "Sumin (Korean Mother Tongue) is top or near top of her classes in all mainstream subjects at her current Australian Curriculum school.",
        grades: [
          {
            id: 1,
            subject: "Yr 10 Humanities",
            score: "78%",
            rank: "5th out of 30 students"
          },
          {
            id: 2,
            subject: "Yr 10 Chemistry",
            score: "83%",
            rank: "5th out of 30 students"
          },
          {
            id: 3,
            subject: "Yr 10 ESL English",
            score: "96%",
            rank: "Top student in cohort"
          },
        ]
      },
      {
        id: 3,
        quote: 'Good afternoon Mr. Hudson, I’m so glad to hear your acknowledgement to Congcong. To be honest I did have some concerns in the very beginning of the online course: if she could understand the teachings, if she could concentrate as it is online, will she like it and so on. After the first week, I was seeing the delightfulness on her face and she can share with me what she has learnt in English. I’m also very happy to see that she has the courage to raise questions and stick to her opinions. Thank you so much for your good teaching, encouragement and care for Congcong, I am looking forward to her starting her face to face schooling in the Australian Curriculum.',
        source: 'Congcong’s Mum - (China)',
        result: "Congcong (Chinese Mother Tongue) is consistently near or at the top of her classes in all subjects from her first day face to face at her Australian Curriculum school.",
        grades: [
          {
            id: 1,
            subject: "Yr 9 Commerce",
            score: "78%",
            rank: "3rd out of 30 students"
          },
          {
            id: 2,
            subject: "Yr 9 Physics",
            score: "98%",
            rank: "Top student in cohort"
          },
          {
            id: 3,
            subject: "Yr 9 English",
            score: "81%",
            rank: "Top student in cohort"
          },
        ]
      },
      {
        id: 4,
        quote: 'Dear Mr Hudson, we would like to thank you for all the work you have done helping Ryoma learn the English he needed for school. He has developed much more confidence when using English in his subjects at school thanks to your teaching and encouragement. Thank you!',
        source: "Ryoma's Mum - (Japan)" ,
        result: "Ryoma (Japanese Mother Tongue) averaged B+ across all his mainstream subjects before moving back to Japan for family reasons."
      },
    ];

    return (
      <Container className="pt-5">
        <h2>Recent Testimonials</h2>

        <Image src={wherebyPhoto} fluid/>
        
        <Row className="pt-3">
          {testimonials.map(testimonial => (
          <Col key={testimonial.id} xs={12} md={6} className="pt-3 px-5">
            <blockquote>
              <p className="fst-italic">"{testimonial.quote}"</p>
              <footer>
                {testimonial.source}<br></br>
                <small className="text-muted">{testimonial.result}</small>

                {testimonial.grades !== undefined && testimonial.grades.map(grade => (
                    <div className="pt-3 fw-bold" key={grade.id}>
                      {grade.subject} - {grade.score} ({grade.rank}) <br></br>
                    </div>
                ))}
              </footer>
            </blockquote>
          </Col>
          ))}
        </Row>
    </Container>
    );
};

export default TestimonialsScreen;