import { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

const OrgResetPasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if ( password !== confirmPassword ) {
      setPassword("");
      setConfirmPassword("");
      window.alert("Passwords do not match.");
    }

    await axios.post(
      `/auth/org-reset-password/${resetToken}`,
      {
        password,
      },
      config
    ).then(
      () => {
        window.alert("Password reset successful.");
        navigate("/org-login");
      }
    ).catch(
      () => {
        window.alert("Password reset failed.");
      }
    );
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={resetPasswordHandler} >
        <h3>Organisation Reset Password</h3>
        <hr className="mb-5"/>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>New Password:</Form.Label>
          <Form.Control type="password" required placeholder="Enter New Password" autoComplete="true" value={password} onChange={(e) => setPassword(e.target.value)} tabIndex={1}/>
        </Form.Group>
        <Form.Group className="mb-5" controlId="formConfirmPassword">
          <Form.Label>Confirm New Password:</Form.Label>
          <Form.Control type="password" required placeholder="Confirm new password" autoComplete="true" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} tabIndex={2}/>
        </Form.Group>
        <Button variant="primary" type="submit" tabIndex={3}>
          Reset Password
        </Button>
      </Form>
    </Container>
  );
};

export default OrgResetPasswordScreen;
