import React, {useEffect} from "react";

import { Row, Container, Image } from "react-bootstrap";
import studentLaptopPhoto from "../../resources/StudentLaptop.jpg";

const HomeScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Row className="py-5">
        <h1 className="text-primary fst-italic">Creating academic equity for EAL learners</h1>

        <p className="pt-5">Is your child:</p>

        <h5 className="fw-bold">planning to commence secondary schooling in an English medium school</h5>

        <p>or</p>

        <h5 className="fw-bold">currently attending secondary schooling in an English medium school setting in Australia, or an international school?</h5>

        <h2 className="text-primary pt-5">Do you want to help your child be more academically successful at school?</h2>
      </Row>
      <Row>
        <p>
          Our individual (one-on-one) live online academic language modules will:
        </p>
        <ul className="px-5">
          <li>prepare and boost your child's academic language confidence and skills in important Australian Curriculum subjects (Mathematics, Science, Geography, History, Commerce, Visual Arts, Music and English);</li>
          <li>enable your child to perform optimally in secondary school classrooms and their all important assessment tasks;</li>
          <li>enable your child to become a lifelong, self-directed academic language learner;</li>
          <li>ultimately provide your child with access to a wider range of post-school universities and courses</li>
        </ul>
      </Row>

      <Image src={studentLaptopPhoto} fluid />

      <Row>
        <h2 className="text-primary mt-5">Bridge your child's academic English gap</h2>

        <p className="pt-5">
          Research demonstrates that upon arrival for face-to-face learning at English medium of instruction secondary schooling, EAL (English as an Additional Language) learners
          very often find the academic and social transition extremely challenging, and it can take anywhere from three months or longer to begin to 'settle in'. Lack of subject
          specific academic language skills often masks students' abilities and knowledge in school subjects, in some cases leading to schools making incorrect judgments about 
          EAL learners' cognitive abilities. AcLangEDGE offers a unique and effective solution to ensure EAL students are best prepared for their first and each day of face-to-face
          learning at their new English medium school, preparing students via one-on-one regular and flexibly scheduled 60 minute online live lessons, prior to and post arrival 
          at their destination English medium of instruction school. By engaging in an AcLangEDGE course prior to and/or whilst attending their English medium of instruction school, 
          your child will have the confidence and language skills to:
        </p>

        <ul className="px-5">
          <li><span className="fw-bold">self advocate, pose and answer questions confidently in class</span> with peers and teachers</li>
          <li><span className="fw-bold">read and comprehend academic texts</span> for main idea and supporting detail</li>
          <li><span className="fw-bold">view, comprehend and respond to relevant academic video content</span> often used by teachers in class</li>
          <li><span className="fw-bold">write relevant academic text types</span> across various subjects</li>
          <li><span className="fw-bold">learn the academic language acquisition skills required to succeed across all subjects</span> in their English medium school</li>
        </ul>
      </Row>
    </Container>
  )
}

export default HomeScreen;