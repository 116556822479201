import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

import instance from "../../../services/api";
import { config } from "../../common/Constants";

const stripePromise = loadStripe(config.stripePublishableKey);

const CheckoutScreen = () => {
    const location = useLocation();
    const [clientSecret, setClientSecret] = useState("");
    const moduleId = location.state.module._id;

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchData() {
            // Create PaymentIntent as soon as the page loads
            await instance.post("/payment/create-payment-intent", {
                moduleId: moduleId,
            }).then((response) => setClientSecret(response.data.clientSecret));
        }

        fetchData();
    }, [moduleId]);
    
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <Container className="mt-5">
            {clientSecret && (
                <div>
                    <h3>Checkout</h3>
                    <hr/>
                    <br/><br/>
                    <Row>
                        <Col xs={12} md={6}>
                            <h4>Module</h4>
                            <hr/>
                            <br/>
                            <p className="fw-bold">{location.state.module.name}</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <h4>Price</h4>
                            <hr/>
                            <br/>
                            <p className="fw-bold">$495.00 AUD</p>
                        </Col>
                    </Row>

                    <br/><br/>
                    Please enter your payment method and click 'Pay' to complete your purchase.
                    <br/><br/>
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm/>
                    </Elements>
                </div>
            )}
        </Container>
    );
}

export default CheckoutScreen;