import React, { useEffect } from 'react';

import { Container } from "react-bootstrap";
import Course  from "./Course";

const coursesData = [
    {
        title: 'Academic Vocabulary Acquisition',
        subModules: [
            { title: "AcLangEDGE Five Pillars (Planning for Vocabulary Acquisition)", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSP9vBx9s6aVt20q9sNRiEZT5bSSGlO2pikISAu4mJqgSEv-jjmZz5azU37xMjfJiumbce6dyKkRQdt/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vQq7dcvh-XHPltqWwDHEY2Boyr4P3e6JzjjY4TtA3obErrDWmF4Cc5gZRyCOA30A0GMMmXOXlp9L3K8/pub?embedded=true", fileName: "1A" },
            { title: "Classifying Academic Vocabulary", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vRXPIavc2RXA8Nm2a4HAUEx6rdeCtrAC8Mg0d3ZPiyQ7EkX6vLARyNrCL7rZjLtpesXiILcRgtP87hV/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vS-3g8tBHg1VfPwrweRsKuNAaLmhIf2XVANN4PJ59ARw5IDoTabdkiorljurnttX1HJhQRh7q-CZMHp/pub?embedded=true", fileName: "1B" },
            { title: "Defining, Identifying and Classifying Collocations", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQHkv-EoYSzmfJH8vfLD0c8En9DVSQXJmz-LnKQVT7aw0rzjq-7tJBUHE4euYF6wt1rPIje3oVZG-bp/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vQOHrd0lFYmnemtyEduWyS9mHhgp2JBXAZ9vCAKbs5CFKJ4E_L3iybAxfP3VdmVj2w2apgJDaEf5FMH/pub?embedded=true", fileName: "1C" },
            { title: "Joining Quizlet and Creating a Quizlet Set", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQ905CJGbcQGuuQW6XTQnXkTtMKOQRDp7Y8bHFDLQr02e1A0eAoSD7InjRnw9FnH69f5ZYLb5bUs8XV/embed?start=false&loop=false&delayms=3000" },
            { title: "Creating and Sharing a Quizlet Class - Benefits of QuizletPlus", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vTTsHYDs52ojnktg6PplITrMisMJyTGP8eVLwytv5Z9uXQwwvThDtGNfB-iYCa-07mllrrUX_QGYj39/embed?start=false&loop=false&delayms=3000" },
            { title: "Quizlet Tips - In the Classroom and at Home", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSm59Vz1elerW7SCImAERavBo1rk8C263luQMip-FeJFpMBYzdlEInG15x6J6Dwczf89dlrdhdYnNaF/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vTryo8hUeExdy3Ow6Bv8iBITeeu0ndHA-qARVr2s423hMEqOixMsZ2GfZYQWrkqL-sscn2A6zO8EwlS/pub?embedded=true", fileName: "1F" },
            { title: "Using Quizlet LIVE and TEST", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQUMBmiUA_jlWSsNtSr5x_O97G24DliBmQdr_ZfNba2wMnVEAIgGoUBnQ55npVmI8KS8FDRy2r2oXGC/embed?start=false&loop=false&delayms=3000" },
            { title: "Academic Vocabulary Recycling Strategies", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSEA3173_37w7XjOS03Z0WRxnTpyl6Fti6BLBHs4Ghl18F-OytNEjMhRjOt-tBuQ2QTDKvfXOIzu4CR/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vRv5Rv2vW9suNXB2qSzZBtKbb-hFx_djnj727lRZ0pF-yOj8zlGFwbRMK7bq6hF-KXpDNPEp-dDHCJh/pub?embedded=true", fileName: "1H" },
        ]
    },
    {
        title: 'Developing MLL Academic Language Comprehension Skills',
        subModules: [
            { title: "Promoting Active Listening and Viewing - EdPuzzle", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQ1iWfol_zWBAVNpfMLdtWpRL5IdarCLLnil4sCso0i0xap8-k3Oj9Hu8VCXBC8Y0gb7eR-wZIXw8TM/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vTCOmGjTbmD1eZr1MtWi5xhPr8bmR7Vzy_UNRfxCWD8Za87FekJN-u2QJlXUXqFa9LCF2nljpc3V5_d/pub?embedded=true", fileName: "2A" },
            { title: "Teaching Questioning Techniques", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSM9jYwyHBc6CUEti1DKAt5k3S1z2XDvhgcaEhLyfHlgwPCAbcy1CgOEFMmfQ1tBPFuqS6gyGt5_Ql3/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vRptm32ABW9PyWks8E4pjLmM8_tcsTfwCjbAYoRrLxiajaJnI_jgYxZLNaHFv-_DeMmY9icHHQqWzUr/pub?embedded=true", fileName: "2B" },
            { title: "Dictogloss - Differentiation Strategies", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQxPK8-cgOdNUVNWX7a8BpQ1tvoI4mrZUtIna6ttvCyzhoipDfj_liSWTcvpO5dlwI7w6yhmiitkaI2/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vTJm_yJ-0u4_Enhx6AFyxAN5zyHDldTzw_K2jvUETNeFl_Yeziy1NUgtlXgfqIBNSNulML_QFHZnQmx/pub?embedded=true", fileName: "2C" },
            { title: "Pre-Reading Routines for the Classroom", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQisGbwPYCySSk7RVsNexxi9RnEDnGx3uEUZPZqpu5fke5HyDpAzCppmvLztYTsOVZoKcvFtznK5DDh/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vRaRCgb7qMrRKkzYSxYTjiDHIPBW9DQ__7LwaI_oJkrqtCLtQTwoopOFPsnsi4pz1sooV0h7N_5ntk7/pub?embedded=true", fileName: "2D" },
            { title: "Using ChatGPT to Differentiate Reading Tasks", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vRu2l_LkW8ajB1pQqDOvsIYirps7vY4DDYNP8XG308Z9jBao0QRKRW7Joxf5T-qi2skW8Yzq58IjHsC/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vSHiOiUJfvxeS7bfn7Qi_WPtaAdeKV5AKuKt99azmBEKN1Fkr-OHwNLVHwlo9CRGx1OaGH9bTayzEsU/pub?embedded=true", fileName: "2E" },
            { title: "Jigsaw Reading Strategies", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQnCYAsyhgCl9tPd9ySd_MqlXRrXOUjJotnelM4e9fAVZJ-ao3ElLbx3FUsjvxZBjJrdPOq6MYqHxbh/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vTXm9Kxmo0NZupJs2VEO7HSCcCcyraXcNwua4ck3VLhRLyBaNWGLp0wRKUpS4jj-_yCkPK_YBzCDkKI/pub?embedded=true", fileName: "2F" },
            { title: "Developing Effective Cloze Activities", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSr3n-E91I4mWamFrcxA4X2ocRJduVMUxsChKnYWDivrjyaq3fcUeFFuixvKQMd2CeqJtEF7bqkeu5V/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vQmQvdCLRmAU95WoN68WVyCIve9PTZAsash-CL1AfuNwGcBA77wB1aU1c7_aff1vxrqk4MEip4KTrfH/pub?embedded=true", fileName: "2G" },
            { title: "Intensive and Extensive Reading - Graded Reading Programs", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vSqAgMKNxg1fQgataxwz2y8hE7-yIz-bZtmey7xEVyeITbqnq6-cfBmdnehgIps6QA8nNE233SeiO4b/embed?start=false&loop=false&delayms=3000" },
        ]
    },
    {
        title: 'Developing MLL Academic Language Production Skills',
        subModules: [
            { title: "Text Types - Features and Scaffolds", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vRBKSS_CSKSsr5c5pdcejxmuNrBnOkLyixOeZQJhelWc5w3BKHUhtFlcMF5xz1UMNahFxdHxFVvFR4B/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vQfE_qPNYewsNJyVUX1NoxvFXBXjwtfsjWn_pVVsb6HTwM6FltH41oxnmgFkH-sYUp0cwaI39YSmgdz/pub?embedded=true", fileName: "3A" },
            { title: "Teaching Paragraph Writing Across the Curriculum", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vT0DwQJ0F1IwdYfSRfoCbxc0oCzpGuh-GywIMekLCHvWlnPh10LG4gVe0sEFJkDjuNRfi10_6sBIWKO/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vTK35Kb2g3Uvf41TZbvfUz5O2bUcr-UHxxJ6pgissi-bSKCclMtCedXihTKyJmFplT3zjTRBKqHg_qY/pub?embedded=true", fileName: "3B" },
            { title: "The Important Role of Students' L1 in L2 Acquisition", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vTmGfzzjJwL_iXyyJtsAdXZ9XZYrjBCnwk4010W3GGrbhhh3lViYxpuEnjqaH79Gq6m1migAA_Mxn_5/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vQgIdjkgFeM18v51euZuouQxIJYmB3JJqZXfHS9Y0MaFDA9h-GU64nHlnzjvwd_jj9Daojf5to-zivV/pub?embedded=true", fileName: "3C" },
            { title: "Language Production Error Treatment and Error Coding Strategies", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vS-EYxkJJmUqaW1rPgyMrZP1Wobpi0NJEOb6TNT79YQJaUor0rgWkYAf4gigiHKsgOL67kCsjcltDlO/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vRLH4FE_nmR3q7GkRf9Wh5O6bsXjEZWIdxgG3unW1lDtNpWx3W7ux_v3O4O2nhYZ7rgjs5gQ7Zvw2di/pub?embedded=true", fileName: "3D" },
            { title: "Command Terms and Supporting Sentence Frames", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vRdNC92lm_62r2NK40zAioK1Thn20WU4IOONgZpfIEvSViq2yMQDLa4mA_LBN-e81Yn47rS2VyHUvcW/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vRMQyWzty3OPNC4iI3-dN2CwAucdH-RXqf3-kKaDMMQDgfoXqY_gnUWoYOxFxH3sM4SCJ2fpU5gy1xe/pub?embedded=true", fileName: "3E" },
            { title: "Research Task 'Scaffolding Up' with Embedded Translanguaging", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vQ7eKtBWRmqk7O6yYk0EXVKZosZpxwFNz8uUvxMBD7gza9_52h6TsxwP0m86-Os9lSP8rKf0JUnLskr/embed?" },
            { title: "Understanding the 'Silent Period', Wait Time and Cooperative Learning Basics", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vRr_9gYKkSaX-tIKGZ-cdF8okrFH79sclK03r-BeHZt2VGLmiOHBhfreQQBmMcfQvfY5WT4-P10U83T/embed?start=false&loop=false&delayms=3000" },
            { title: "'Think-Pair-Share' and Inclusive Questioning Techniques", slidesLink: "https://docs.google.com/presentation/d/e/2PACX-1vTHNHOfnFHxTX9hSrnLFCYomHdLwif7_wtSObsP_li5EYk8IstfQhHmgVGNG4Uh72uDb3VM_Yr9GRlS/embed?start=false&loop=false&delayms=3000", docLink: "https://docs.google.com/document/d/e/2PACX-1vR3TQW4mRnb7eJEpXEPBNxk0pug_5R9OeeVQG5eNUJCHe9lBWQ9sCVVpG9zaYvTb-jl8bcFAEpGhN3l/pub?embedded=true", fileName: "3H" },
        ]
    }
]

const CoursesScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className="pt-5">
            <h2>Professional Development Courses</h2>
            <hr/>
            {coursesData.map((course, index) => (
                <Course key={index} {...course} />
            ))}
        </Container>
    )
}

export default CoursesScreen;