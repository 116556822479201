import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Calendar } from "react-calendar";
import Select from "react-select";
import 'react-calendar/dist/Calendar.css'

import instance from "../../../services/api";

const UserBookingsScreen = () => {
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().getTime()+(2*24*60*60*1000)));
  const [bookedTimes, setBookedTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [selectModuleOptions, setSelectModuleOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchUser = () => {
      instance.get("/user")
        .then(( response ) => {
          setUser(response.data);
        })
        .catch(( err ) => {
          console.error(err);
        })
    };

    // Get the user first, because no need to look for modules if user hasn't done consultation.
    fetchUser();
  }, [])

  useEffect(() => {
    setSelectedModule({});
    
    async function fetchData() {
      const moduleResponse = await instance.get("/module");
      const moduleData = moduleResponse.data;

      const options = moduleData
        .filter((module) => module.availableLessons > 0)
        .map((module) => ({
          value: module,
          label: module.name + " (Year " + module.yearLevelMin + "-" + module.yearLevelMax + ")"
        }));

      if ( !user || !user.freeConsultationBooked ) {
        options.push({
          value: {
            name: "AcLangEDGE Free Consultation",
          },
          label: "AcLangEDGE Free Consultation"
        });
      }

      setSelectModuleOptions(options);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    setSelectedTime("");
    setSelectedButtonIndex(null);

    const fetchBookingsByDate = async () => {
      const offsetDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000);
      const newDate = offsetDate.toISOString().split('T')[0];
      await instance.get(`/booking/${encodeURIComponent(newDate)}`)
        .then(( response ) => {
          const bookedTimes = [];
          response.data.forEach((time) => {
            bookedTimes.push(time.split("T")[1].substring(0,5))
          })

          setBookedTimes(bookedTimes);
        })
        .catch(( err ) => {
          console.error(err);
        })
    };

    fetchBookingsByDate();
  }, [selectedDate]);

  const onButtonClickHandler = (time, index) => {
    setSelectedTime(time);
    setSelectedButtonIndex(index);
  }

  const bookLessonHandler = async () => {
    const [hours, minutes] = selectedTime.split(":");
    const chosenDate = selectedDate;

    chosenDate.setHours(hours);
    chosenDate.setMinutes(minutes);
    chosenDate.setSeconds(0);
    chosenDate.setMilliseconds(0);

    const offsetDateTime = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000);
    const bookingDateTime = offsetDateTime.toISOString();

    var response;
    if ( selectedModule.value.name === "AcLangEDGE Free Consultation" ) {
      response = await instance.post("/booking/free-consultation", { bookingDateTime });

      if ( response.status !== 200 ) {
        window.alert("Booking failed!");
        return;
      }

      setUser({
        ...user,
        freeConsultationBooked: true
      });
    } else {
      const moduleId = selectedModule.value._id;
      
      response = await instance.post("/booking", {
        moduleId,
        bookingDateTime
      });

      if ( response.status !== 200 ) {
        window.alert("Booking failed!");
        return;
      }

      const updatedModule = {
        ...selectedModule.value,
        availableLessons: selectedModule.value.availableLessons - 1
      };

      const updatedSelectModuleOptions = selectModuleOptions.map((option) => {
        if ( option.value._id === moduleId ) {
          if ( updatedModule.availableLessons === 0 ) {
            return null;
          }

          return {
            ...option,
            value: updatedModule,
            label: updatedModule.name + " (Year " + updatedModule.yearLevelMin + "-" + updatedModule.yearLevelMax + ")"
          }
        } else {
          return option;
        }
      });

      setSelectedModule({});
      setSelectModuleOptions(updatedSelectModuleOptions.filter((option) => option !== null));
    }

    if ( response.status !== 200 ) {
      window.alert("Booking failed!");
    } else {
      if ( selectedModule.value.name === "AcLangEDGE Free Consultation" ) {
        window.alert("Free consultation booked!");
      } else {
        window.alert("Booking successful!");
      }
    }

    setSelectedDate(new Date(new Date().getTime()+(2*24*60*60*1000)));
    setSelectedTime("");
    setSelectedButtonIndex(null);
  }

  return (
    <Container className="pt-5">
      <h3>My Consultation/Lesson Bookings</h3>
      <hr className="mb-5"/>
      <Row className="pt-3">
        <Col>
          Please select a module from the drop-down below, or select "AcLangEDGE Free Consultation" if you are booking a free consultation: 
          <Select value={selectedModule} options={selectModuleOptions} onChange={setSelectedModule}></Select>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col>
          <Calendar onChange={setSelectedDate} value={selectedDate} minDate={new Date(new Date().getTime()+(2*24*60*60*1000))}/>
        </Col>
        <Col>
          <h3>Available Times</h3>
          <hr/>
          <AvailableTimesComponent onButtonClickHandler={onButtonClickHandler} bookedTimes={bookedTimes} selectedButtonIndex={selectedButtonIndex}/>
          <br/>
          <p>Note that booking times above are in <span className="fw-bold">Brisbane local time.</span> Please use this <Link to="https://www.timeanddate.com/worldclock/converter.html?p1=47&p2=33&p3=95&p4=248&p5=215&p6=235&p7=47" target="_blank">link</Link> to see information on timezone differences.</p>
        </Col>
      </Row>
      { selectedTime.length > 0 && selectedModule.hasOwnProperty("value") &&
        <div>
          <Row className="pt-5">
            <Col>
            { 
              <div>
                Booking for <span className="fw-bold">{new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]}</span> at <span className="fw-bold">{selectedTime}</span> for <span className="fw-bold">{selectedModule.value.name}</span>
              </div>
            }
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <Button variant="primary" onClick={bookLessonHandler}>Book</Button>
            </Col>
          </Row>
        </div>
        
      }
      
    </Container>
  )
}

const AvailableTimesComponent = ({ onButtonClickHandler, bookedTimes, selectedButtonIndex }) => {
  const possibleTimes = ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];

  return (
    <div>
      { possibleTimes.map((time, index) => {
        return ( 
          bookedTimes.includes(time) ? (
            <span key={time}></span>
          ) : (
            <Button 
              variant= { selectedButtonIndex === index ? "primary" : "secondary" }
              key={time} 
              className="me-2 my-2" 
              onClick={() => onButtonClickHandler(time,index)}>{time}</Button>
          )
        )
      }) }
    </div>
  );
};

export default UserBookingsScreen;