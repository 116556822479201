import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Calendar } from "react-calendar"
import 'react-calendar/dist/Calendar.css'

import instance from "../../../services/api";

const AdminScreen = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [bookedLessons, setBookedLessons] = useState([]);
    const [comment, setComment] = useState('');

    const [username, setUsername] = useState('');
    const [isGroup, setIsGroup] = useState(false);
    const [ctFullName, setCtFullName] = useState('');
    const [ctPosition, setCtPosition] = useState('');
    const [ctPhone, setCtPhone] = useState('');
    const [ctEmail, setCtEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [orgAddress, setOrgAddress] = useState('');
    const [orgSchools, setOrgSchools] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchScheduleByDate = async () => {
            const offsetDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000);
            const newDate = offsetDate.toISOString().split('T')[0];
            await instance.get(`/admin/get-schedule/${encodeURIComponent(newDate)}`)
                .then(( response ) => {
                    const lessons = [];
                    response.data.forEach((lesson) => {
                        lessons.push(lesson);
                    })

                    setBookedLessons(lessons);
                })
                .catch(( err ) => {
                    console.error(err);
                })
        };

        fetchScheduleByDate();
    }, [selectedDate]);

    const blockTimeHandler = async ( time ) => {
        const [hours, minutes] = time.split(":");
        let chosenDate;
        chosenDate = selectedDate;

        chosenDate.setHours(hours);
        chosenDate.setMinutes(minutes);
        chosenDate.setSeconds(0);
        chosenDate.setMilliseconds(0);

        const offsetDateTime = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000);
        const dateTime = offsetDateTime.toISOString();

        await instance.post("/admin/block-time", {
            dateTime, comment
        }).then(() => {
            window.alert("Time blocked!");
        }).catch(() => {
            window.alert("Block failed!");
        })
    }

    const unblockTimeHandler = async ( lesson ) => {
        await instance.delete("/admin/block-time/" + lesson._id).then(() => {
            setBookedLessons(bookedLessons.filter((item) => item._id !== lesson._id));
            window.alert("Time unblocked!");
        }).catch(() => {
            window.alert("Unblock failed!");
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if ( password !== confirmPassword ) {
            setPassword("");
            setConfirmPassword("");
            window.alert("Passwords do not match.");
            return;
        }

        if ( password.length < 6 ) {
            setPassword("");
            setConfirmPassword("");
            window.alert("Password must be at least 6 characters long.");
            return;
        }

        await instance.post("/admin/org-register", {
            username,
            password,
            isGroup,
            ctFullName,
            ctPosition,
            ctPhone,
            ctEmail,
            orgName,
            orgAddress,
            orgSchools,
        }).then(() => {
            window.alert("Successfully registered new organisation/school!");
        }).catch(() => {
            window.alert("Failed to register new organisation/school!");
        });
    };

    return (
        <Container className="pt-5">
            <h3>Scheduler</h3>
            <hr className="mb-5"/>
            <Row className="pt-5">
                <Col>
                    <Calendar onChange={setSelectedDate} value={selectedDate} className="mb-5"/>
                    <h4>Comment for Blocked Time</h4>
                    <textarea type="text" onChange={(e) => setComment(e.target.value)} value={comment} rows={4} cols={40}></textarea>
                </Col>
                <Col>
                    <h3>Schedule</h3>
                    <hr></hr>
                    <ScheduleComponent blockTimeHandler={blockTimeHandler} unblockTimeHandler={unblockTimeHandler} bookedLessons={bookedLessons} />
                </Col>
            </Row>
            <Row className="pt-5">
                <h3>Professional Development School/Organisation Registration</h3>
                <hr className="mb-3"></hr>
                <Form onSubmit={submitHandler}>
                    <Form.Group className="mb-3 mt-5" controlId="formUsername">
                        <Form.Label>Account Username:</Form.Label>
                        <Form.Control required placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>Password:</Form.Label>
                        <Form.Control type="password" required autoComplete="true" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="formConfirmPassword">
                        <Form.Label>Confirm Password:</Form.Label>
                        <Form.Control type="password" required autoComplete="true" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroup">
                        <Form.Check type="checkbox" label="Multiple schools?" checked={isGroup} onChange={(e) => setIsGroup(e.target.checked)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCtFullName">
                        <Form.Label>Contact Full Name:</Form.Label>
                        <Form.Control required placeholder="Contact Full Name" value={ctFullName} onChange={(e) => setCtFullName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCtPosition">
                        <Form.Label>Contact Position:</Form.Label>
                        <Form.Control required placeholder="Contact Position" value={ctPosition} onChange={(e) => setCtPosition(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCtPhone">
                        <Form.Label>Contact Phone:</Form.Label>
                        <Form.Control required placeholder="Contact Phone" value={ctPhone} onChange={(e) => setCtPhone(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCtEmail">
                        <Form.Label>Contact Email:</Form.Label>
                        <Form.Control type="email" required placeholder="Contact Email" value={ctEmail} onChange={(e) => setCtEmail(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOrgName">
                        <Form.Label>Organisation Name:</Form.Label>
                        <Form.Control required placeholder="Organisation Name" value={orgName} onChange={(e) => setOrgName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOrgAddress">
                        <Form.Label>Organisation Address:</Form.Label>
                        <Form.Control required placeholder="Organisation Address" value={orgAddress} onChange={(e) => setOrgAddress(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="formOrgSchools">
                        <Form.Label>If registering for multiple schools, please list them below:</Form.Label>
                        <Form.Control placeholder="Schools (e.g. School1, School2, School3)" value={orgSchools} onChange={(e) => setOrgSchools(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Confirm Details and Register</Button>
                </Form>
            </Row>
        </Container>
    )
}

const ScheduleComponent = ({ blockTimeHandler, unblockTimeHandler, bookedLessons }) => {
    const timeSlots = ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];
    const schedule = timeSlots.map((time) => {
        const lesson = bookedLessons.find((lesson) => {
            const lessonTime = lesson.date.split("T")[1].substring(0,5);
            return lessonTime === time;
        });

        if ( lesson ) {
            // Booking for an actual user, not the admin
            if ( lesson.user ) {
                // Booking for a module, not a free consultation
                if ( lesson.module ) {
                    return (
                        <div key={time}>
                            <p className="fw-bold">{time}</p> 
                            <p className="fst-italic">{lesson.module.name}</p>
                            <p>{lesson.user.fullName}, {lesson.user.email}, {lesson.user.group}</p>
                        </div>
                    );
                } else {
                    return (
                        <div key={time}>
                            <p className="fw-bold">{time}</p> 
                            <p className="fst-italic">Free Consultation</p>
                            <p>{lesson.user.fullName}, {lesson.user.email}, {lesson.user.group}</p>
                        </div>
                    );
                }
            } else {
                // Booking for admin, unblock option
                return (
                    <div key={time}>
                        <span className="fw-bold">{time}</span> <Button variant="primary" className="mx-2 my-2" onClick={() => unblockTimeHandler(lesson)}>Unblock</Button>
                        <p className="fst-italic" style={{ width: "300px", overflow: "auto"}}>{lesson.comment}</p>
                    </div>
                );
            }
        } else {
            return (
                <div key={time}>
                    <span className="fw-bold">{time}</span> <Button variant="primary" className="mx-2 my-2" onClick={() => blockTimeHandler(time)}>Block</Button>
                </div>
            );
        }
    });

    return <div>{schedule}</div>;
};

export default AdminScreen;