import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

const RegisterScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [group, setGroup] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const navigate = useNavigate();

  const registerHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if ( password !== confirmPassword ) {
      setPassword("");
      setConfirmPassword("");
      window.alert("Passwords do not match.");
      return;
    }

    if ( password.length < 6 ) {
      setPassword("");
      setConfirmPassword("");
      window.alert("Password must be at least 6 characters long.");
      return;
    }

    if ( fullName.length === 0 ) {
      window.alert("Please provide a full name.");
      return;
    }

    if ( group.length === 0 ) {
      window.alert("Please specify the group that you belong to, or represent.");
      return;
    }

    axios.post(
      "/auth/register",
      {
        email,
        fullName,
        group,
        password
      },
      config
    ).then(
      () => {
        window.alert("Registration successful, please check your email to verify your email address and then login.");
        navigate("/login");
      }
    ).catch(
      ( error ) => {
        console.log(error);
      }
    );
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={registerHandler}>
        <h3>Register New Account</h3>
        <hr className="mb-5"/>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control type="email" required placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} tabIndex={1}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formFullName">
          <Form.Label>Full Name:</Form.Label>
          <Form.Control required placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} tabIndex={2}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroup">
          <Form.Label>Group:</Form.Label>
          <Form.Select value={group} onChange={(e) => setGroup(e.target.value)} tabIndex={3}>
            <option value="">Please select the group that you belong to, or represent</option>
            <option value="Student">Student</option>
            <option value="Agent">Agent</option>
            <option value="School">School</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password:</Form.Label>
          <Form.Control type="password" required autoComplete="true" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} tabIndex={4}/>
        </Form.Group>
        <Form.Group className="mb-5" controlId="formConfirmPassword">
          <Form.Label>Confirm Password:</Form.Label>
          <Form.Control type="password" required autoComplete="true" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} tabIndex={5}/>
        </Form.Group>
        <Button variant="primary" type="submit" tabIndex={6}>
          Register
        </Button>

        <Form.Text className="mx-3" muted>
          Already have an account? <Link to="/login" tabIndex={7}>Login</Link>
        </Form.Text>
      </Form>
    </Container>
  );
};

export default RegisterScreen;
