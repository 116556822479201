import axios from "axios";
import { config } from "../components/common/Constants";

const instance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken");
        if ( token ) {
            config.headers["Authorization"] = `Bearer ${token}`;
        } else {
            window.location.href = "/login";
            return Promise.reject("No token");
        }

        return config;
    },
    (err) => {
        window.location.href = "/login";
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        return Promise.reject(error);
    }
);

export default instance;