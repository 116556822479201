import React, {useEffect} from 'react';

import Container from "react-bootstrap/Container";

const TermsAndConditionsScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])  

    return (
        <Container className="pt-5">
            <h2>AcLangEDGE Terms and Conditions</h2>
            <hr/>

            <h3 className="pt-5">Section A: Access to AcLangEDGE Student Resources</h3>

            <p>
              These Terms and Conditions apply to the teaching of Module Lessons and Student Resources supplied via email to Users. By applying for and enrolling in an AcLangEDGE course, users acknowledge and agree that they have had sufficient opportunity to read and understand these Terms and Conditions, and that they agree to be bound by them. Users who do not agree to these Terms and Conditions must discontinue their Module Enrolment immediately.
            </p>

            <h4>1. Definitions and Intepretation</h4>
            <h5>1.1 Defined Terms</h5>
            <p>
              <ul>
                <li>Access means downloading and opening Student Resources that are made available by the teacher via email before, during and/or after each module lesson.</li>
                <li>Commencement Date means the date the User pays for AcLangEDGE Module access.</li>
                <li>Student Resources means resources made available to User via email from the teacher.</li>
                <li>Insolvency Event means circumstances in which AcLangEDGE or a User take any corporate action or any steps are taken or legal proceedings are started for: 
                  <ol>
                    <li>its winding-up, dissolution, or liquidation;</li>
                    <li>the appointment of a controller, receiver, administrator, official manager, trustee or similar officer of it; or</li>
                    <li>seeking or being granted protection from its creditors, under any applicable legislation.</li>
                  </ol>
                </li>
                <li>Intellectual Property means all industrial and intellectual property rights including, without limitation, patents, copyrights, right to extract information from databases, design rights, trade secrets, rights of confidence, and all forms of protection of a similar nature or having similar effect to any of them which may subsist anywhere in the world (whether or not any of them are registered and including applications and the right to make applications for registration of any of them).</li>
                <li>Module Enrolment means the Paid Enrolment.</li>
                <li>Module Fee means payments paid by Direct Debit, for and prior to the agreed period, payable by the User in accordance with the AcLangEDGE’s module enrolments.</li>
                <li>Module Period refers to 5 x 1 hour live one on one teaching lessons.</li>
                <li>Module Lesson is a 1 hour live one on one teaching session.</li>
                <li>Term means the Module Period and any subsequent Module Period as applicable.</li>
              </ul>
            </p>

            <h5>1.2 Interpretation</h5>
            <p>
              Reference to:
              <ul>
                <li>the singular includes the plural and the plural includes the singular;</li>
                <li>a person includes a body corporate;</li>
                <li>to an amount of money, to $, $AUD or dollar is a reference to the currency of Australia;</li>
                <li>a statute, regulation or provision of a statute or regulation (a Statutory Provision) includes:
                  <ul>
                    <li>that Statutory Provision as amended or re-enacted from time to time; and</li>
                    <li>a statute, regulation or provision enacted in replacement of that Statutory Provision;</li>
                  </ul>
                </li>
                <li>including and similar expressions are not words of limitation;</li>
                <li>where a word or expression is given a particular meaning, other parts of speech and grammatical forms of that word or expression have a corresponding meaning.</li>
              </ul>
            </p>

            <h4>2. Accessing Student Resources</h4>
            <p>
            In order to access the Student Resources during and after Module Lessons, ideally users must have a fully functioning device with camera, microphone, and ability to download and print PDF documents shared via email.
            </p>

            <h4>3. Module Fee</h4>
            <p>
              <ul>
                <li>Upon payment of the Module Fee, the User agrees to use and access the Student Resources for the purpose for which the Student Resources are provided.</li>
                <li>The Paid Subscription will automatically cease at the end of the Module Enrolment.</li>
                <li>If AcLangEDGE increases the Module Fee during the period of Module Enrolment, the Users will not be required to make extra payment for the current Module/s Period.</li>
                <li>The User agrees to pay the Module Fee in the manner stipulated by AcLangEDGE.</li>
                <li>The Users are responsible for payment of all applicable taxes in relation to the Module Fee, unless AcLangEDGE advises otherwise.</li>
              </ul>
            </p>

            <h5>3.1 Unauthorised Access and Use</h5>
            <p>
              The Users must not under any circumstances:
              <ul>
                <li>provide Student Resources to any teachers, parents, or other unauthorised users;</li>
                <li>resell any Student Resources;</li>
                <li>provide any Student Resources for profit or a personal benefit; or</li>
                <li>allow any third parties to use the Student Resources including recorded sessions.</li>
              </ul>
            </p>

            <h5>3.2 Ownership</h5>
            <p>
              The Users must not under any circumstances:
              <ul>
                <li>AcLangEDGE retains all right, title and interest in and to all Intellectual Property in the Student Resources.</li>
                <li>The Users acknowledge that they do not acquire any Intellectual Property, either express or implied, in the Student Resources beyond the terms contained in these Terms and Conditions.</li>
              </ul>
            </p>

            <h5>3.3 No Obligation to Support</h5>
            <p>
            Provided the Student Resources are fit for purpose, AcLangEDGE has no obligation to provide support, maintenance, upgrades, modifications or new releases of Student Resources.
            </p>

            <h5>3.4 Termination by AcLangEDGE</h5>
            <p>
            AcLangEDGE reserves the right to limit, cancel, suspend or terminate a Module Enrolment without notice if:
              <ul>
                <li>it no longer has the right to provide the course to the User for any reason whatsoever;</li>
                <li>the User commits a breach of any of the material terms and conditions of these Terms and Conditions or the Module Enrolment, including but not limited to providing the course or Student Resources to unauthorised users;</li>
                <li>the User’s use of the Student Resources may breach a third party’s Intellectual Property rights;</li>
                <li>the activities being conducted by the User contravene any applicable law;</li>
                <li>the User does not pay the Module Fee prior to the agreed Module commencing;</li>
                <li>the User is a body corporate and suffers an Insolvency Event;</li>
                <li>the User is an individual and becomes bankrupt.</li>
              </ul>

              If AcLangEDGE terminates a Module Enrolment pursuant to this clause:
              <ul>
                <li>the User will not be entitled to a refund of any Module Enrolment Fees paid for the Module Enrolment; and</li>
                <li>AcLangEDGE is not liable for claims, demands or damages (including actual and consequential) of any kind for terminating the User’s access to the Module Lessons and Student Resources.</li>
              </ul>
            </p>

            <h5>3.5 Termination by the User for breach</h5>
            <p>
              <ul>
                <li>The User can terminate the Enrolment without notice if AcLangEDGE commits a breach of any of the material terms and conditions of the Module Enrolment.</li>
                <li>AclangEDGE will refund the Module Fee for the agreed period or as applicable on a pro-rata basis if the Module Enrolment is terminated according to the AcLangEDGE Refund Policy.</li>
              </ul>
            </p>

            <h5>3.6 Actions Upon Termination</h5>
            <p>
              Upon termination:
              <ul>
                <li>the User must delete any downloaded or saved Student Resources;</li>
                <li>the User must cease using any Student Resources, except for Student Resources that have been printed prior to the Module Enrolment being terminated;</li>
                <li>any clauses intended to survive termination of this Module Enrolment survive termination</li>
              </ul>
            </p>

            <h4>4. Permitted use of Student Resources</h4>
            <p>
              <ul>
                <li>Unless otherwise indicated, AcLangEDGE reserves all copyright of the Student Resources. AcLangEDGE  owns all such copyright or provides it to Users under the terms of an Module Enrolment embodied in these Terms and Conditions.</li>
                <li>Users may download, print or copy Student Resources for their own use, pursuant to each Module Lesson. Unless provided with a mechanism to do so, Users must not sell, lease, furnish or otherwise permit or cause others to provide access to Student Resources.</li>
                <li>Users must not use, reproduce, communicate, publish, or distribute any of the Student Resources provided by AcLangEDGE, outside the scope of their Module Enrolment, unless this constitutes a fair dealing for the purposes of the Copyright Act 1968 . In particular, Users must not reproduce or use any of the Student Resources for commercial benefit.</li>
                <li>Other than for the purposes of and subject to the conditions prescribed under the Act or as otherwise provided for in the Module Enrolment and these Terms and Conditions, no part of the Student Resources may in any form or by any means (including framing, screen scraping, electronic, mechanical, micro copying, photocopying or recording) be reproduced, adapted, stored in a retrieval system or transmitted without prior written permission.</li>
              </ul>
            </p>

            <h4>5. Prohibitions on Use</h4>
            <p>
            The Student Resources must not be used in any manner that infringes AcLangEDGE’s rights. Users agree that they must not:
              <ul>
                <li>tamper with, hinder the operation of, or make unauthorised modifications to the Student Resources;</li>
                <li>use the Student Resources for any purpose or in any manner not contemplated in these Terms and Conditions or in a manner which could damage the reputation of AcLangEDGE or the goodwill or other rights enjoyed by AcLangEDGE ; or</li>
                <li>transfer, sublicense, rent, lease or lend the Student Resources to a third party for commercial sharing.</li>
              </ul>
            </p>

            <h4>6. Consent to the Provision of Information to Third Parties</h4>
            <p>
            Users acknowledge that their credit card details or PayPal billing agreement will be securely stored in order for Users to access AcLangEDGE’s teaching services and Student Resources, and for Users’ Module Enrolment to be renewed if requested by the User.
            </p>

            <h4>7. Take Down Procedure</h4>
            <p>
            To notify AcLangEDGE of a claimed infringement of copyright please send a notification to aclangedge@gmail.com which includes:
              <ul>
                <li>information about the complaining party (Complainant) including for example: address, telephone number (landline and mobile) and email address;</li>
                <li>an electronic or physical signature of the copyright owner or authorised agent of the owner of the allegedly infringed material;</li>
                <li>identification of the Student Resource that is claimed to be infringing or to be subject to infringing activity and that is to be removed and information reasonably sufficient to permit AcLangEDGE to locate the material;</li>
                <li>a statement that the Complainant has a good faith belief that use of the Student Resource in the manner complained of is not authorised by the copyright owner, agent, or the law; and</li>
                <li>a statement that the information in the notification is accurate and, under penalty of perjury, that the Complainant is authorised to act on behalf of the owner of an exclusive right that is allegedly infringing.</li>
              </ul>
            </p>

            <h4>8. Warranty Disclaimer</h4>
            <h5>8.1 Disclaimer of Warranties</h5>
            <p>
            Users expressly acknowledge and agree that, to the maximum extent permitted by law:
              <ul>
                <li>their use of Student Resources is at their sole risk. The Student resources are provided on an “as is” and “as available” basis. AcLangEDGE expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement;</li>
                <li>AcLangEDGE makes no warranty that:
                  <ul>
                    <li>the Student Resources will meet Users’ requirements;</li>
                    <li>the Student Resources are accurate or reliable;</li>
                    <li>the Student Resources will be error-free;</li>
                    <li>the quality of the Student Resources will meet Users’ expectations; and</li>
                    <li>any errors will be corrected;</li>
                  </ul>
                </li>
                <li>any Student Resource downloaded or otherwise obtained through AcLangEDGE is accessed at Users’ own discretion and risk, and Users are solely responsible for any damage to their computer or loss of data that results from downloading Student Resource; and</li>
                <li>no advice or information, whether oral or written, obtained by Users from AcLangEDGE or through or from Student Resources creates any warranty not expressly stated herein.</li>
              </ul>
            </p>

            <h5>8.2 Limitation of liability</h5>
            <p>
              <ul>
                <li>Except for certain statutory warranties under consumer protection laws, AcLangEDGE does not provide any guarantee or warranty or make any representation of any kind, either express or implied, in relation to the Student Resources.</li>
                <li>Subject to any claims available under consumer protection laws, AcLangEDGE is not liable for any loss or damage, including, but not limited to, direct, indirect or consequential losses including any form of consequential loss such as any third party loss, loss of profits, loss of revenue, loss of opportunity, loss of anticipated savings, pure economic loss and an increased operating cost, personal injury or death, however suffered or sustained in connection with:
                  <ul>
                    <li>any inaccurate or incorrect information in the Student Resources;</li>
                    <li>Users’ use of the Student Resources;</li>
                    <li>any failure or delay including, but not limited to, the use or inability to use any of the Student Resources;</li>
                    <li>any interference with or damage to Users’ computer systems which occurs in connection with use of the Student Resources;</li>
                    <li>for anything a User does to a third party as a result of using the Student Resources;</li>
                    <li>for any inability to use any third party equipment or access to data;</li>
                    <li>for loss or corruption of data, loss of business, loss of profits, loss of revenue and anticipated savings, business interruption of the like regardless of whether the loss is direct or indirectly caused by use of the Student Resources;</li>
                    <li>for any indirect, incidental, punitive, special, or consequential loss or damage whatsoever, in each case, arising out of the use or inability to use the Student resources, even if AcLangEDGE has been advised of the possibility of such damages or if such damages are foreseeable.</li>
                  </ul>
                </li>
                <li>For claims that cannot be excluded or restricted under consumer protection laws, the liability of AcLangEDGE for such a claim will (at AcLangEDGEs option and to the extent permitted by law) be limited to:
                  <ul>
                    <li>in the case of goods: repairing or replacing those good or paying the cost of having those repaired or replaced; and</li>
                    <li>if the breach relates to Student Resources: resupplying that or equivalent Student Resources or paying the cost of having that Student Resources resupplied.</li>
                  </ul>
                </li>
              </ul>
            </p>

            <h5>8.3 Indemnity</h5>
            <p>
            The Users indemnify, defend and hold harmless AcLangEDGE against any claim or proceeding that is made, threatened or commenced, and any liability, loss, damage or expense (including reasonable legal costs on a full indemnity basis) that AcLangEDGE may incur or suffer as a direct or indirect result of:
              <ul>
                <li>any breach of these Terms and Conditions;</li>
                <li>a User’s negligent acts or omissions;</li>
                <li>an actual or alleged breach by a User of any law, legislation, regulation, by-law or code of conduct caused by the Student Resources or other data uploaded or downloaded via AcLangEDGE;</li>
                <li>use of the Student Resources, including any third party claims made in connection with, or arising out of, the User’s use of the Student Resources;</li>
                <li>any claims brought by or on behalf of a third party relating to any act or omission by the User, including breach of a third party’s copyright or trademark.</li>
              </ul>
            </p>

            <h4>9. Notices</h4>
            <p>
              <ul>
                <li>For all correspondence in relation to these Terms and Conditions, please contact AcLangEDGE on:
                  <ul>
                    <li>email: aclangedge@gmail.com or</li>
                    <li>telephone: +61 406081150.</li>
                  </ul>
                </li>
                <li>If required, AcLangEDGE will contact Users via the contact details the User has provided to AcLangEDGE upon Module Enrolment. If AcLangEDGE has multiple contact details for a User, AcLangEDGE will contact the User using the most recent contact details on file.</li>
              </ul>
            </p>

            <h4>10. Miscellaneous Provisions</h4>
            <h5>10.1 Access to the Student Resources</h5>
            <p>
            No representation or warranty is made that the Student Resources comply with the laws of any country outside of Australia.  A User accessing the Student Resources from outside Australia, does so at their own risk.
            </p>
            
            <h5>10.2 Amendments</h5>
            <p>
            AcLangEDGE may change the Terms and Conditions at its discretion by providing notice on the AcLangEDGE website.  The version of the Terms and Conditions that applies to the Users will be available on the AcLangEDGE website each time a User visits the AcLangEDGE website.
            </p>

            <h5>10.3 Entire Agreement</h5>
            <p>
            These Terms and Conditions (including the Enrolment) and any warranties implied by law which are not capable of being excluded or modified amount to the entire agreement with the Users. Any contact with AcLangEDGE that includes any statements representations, warranties (if any) whether expressed or implied, including any collateral agreement or warranty, with reference to the subject matter or the intentions of either of the parties are merged and otherwise are excluded and cancelled by those contained in these Terms and Conditions.
            </p>

            <h5>10.4 Governing Law and Jurisdiction</h5>
            <p>
            The laws of Queensland and Australia govern these Terms and Conditions. AcLangEDGE and the Users submit to the non-exclusive jurisdiction of the Supreme Court of Queensland and the Federal Court of Australia.
            </p>

            <h5>10.5 Severability</h5>
            <p>
            Part or all of a provision of these Terms and Conditions that is illegal or unenforceable may be severed from these Terms and Conditions and the remaining parts of the provision or provisions of these Terms and Conditions continue in force.
            </p>

            <h5>10.6 Waiver</h5>
            <p>
            If AcLangEDGE does not act in relation to a particular breach by a User of these Terms and Conditions, this will not be treated as a waiver of AcLangEDGE and right to act with respect to subsequent or similar breaches.
            </p>

            <h3 className="pt-5">Section B: AcLangEDGE Online Teaching Service</h3>
            <h4>Enrolment</h4>
            <p>
              <ul>
                <li>To enrol your child after the free 30 minute live consultation session (accessed via the website), please complete the online student lesson booking and payment system (password protected) via the AcLangEDGE website confirming your child's module/s and lesson times. Each student’s enrolment will be confirmed upon completion of this online student booking and payment form, with attached receipt of payment of the online lesson bundle enrolment fee.</li>
                <li>To maximise the benefit of AcLangEDGE Online lessons for your child, we encourage enrolling for a minimum of 3 and maximum of 5 one hour lessons per week, depending on your individual circumstances. Student Module Enrolment and confirmed online lesson times will be confirmed via email by AcLangEDGE.</li>
              </ul>
            </p>

            <h4>Session Types</h4>
            <p>
            AcLangEDGE 60 minute Module Lessons are one on one, parents are invited to join as observers at any time during a lesson.
            </p>

            <h5>Cancellation of an AcLangEDGE Module (5 Hours) : </h5>
            <p>
              <ul>
                <li>Cancellation of an AcLangEDGE Module by a parent received ten business days or less prior to the commencement date of the bundle incurs a 15% charge per enrolment to cover administration costs. All cancellations must be made in writing to aclangedge@gmail.com</li>
                <li>Cancellation of an AcLangEDGE module by a parent prior to the end of that module, incurs a charge of one additional hour session per module to cover administration costs. Once online module lessons for a child has commenced, module cancellations must be at least one week in advance or session charges will apply.  All cancellations must be made in writing via email to aclangedge@gmail.com</li>
                <li>Cancellation of an AcLangEDGE Module by AcLangEDGE will see remaining module lesson charges refunded in full.</li>
              </ul>
            </p>

            <h5>Cancellation of a single AcLangEDGE Module Lesson (60 minutes) : </h5>
            <p>
              <ul>
                <li>Cancellation of single module lesson by AcLangEDGE:
                  <ul>
                    <li>parents will be notified by telephone or email, and will be requested by AcLangEDGE for a new time for makeup module lesson.</li>
                  </ul>
                </li>
                <li>Cancellation of a single module lesson by parent:
                  <ul>
                    <li>24 hours or more before the scheduled session: you can reschedule your session for a different time prior to your next session. You are not entitled to a credit.</li>
                    <li>Less than 24 hours before the scheduled session or fail to arrive for a session: you are not entitled to reschedule that session, nor are you entitled to a credit.</li>
                  </ul>
                </li>
                <li>If a student arrives late to a module lesson, the module lesson will still end at the scheduled time, and the student will forfeit the time missed at the beginning of the module lesson.</li>
              </ul>
            </p>

            <h4>Limit of Liability</h4>
            <p>
            AcLangEDGE is not liable or responsible for any travel costs or loss or damage as a result of a substitution, cancellation, or postponement of a module lesson. AcLangEDGE shall assume no liability whatsoever if this event is altered, rescheduled, postponed or cancelled due to a fortuitous event, unforeseen occurrence or any other event that renders performance of this online lesson inadvisable, illegal, impracticable or impossible. For purposes of this clause, a fortuitous event shall include, but shall not be limited to: an outbreak of H1N1 or other types of influenza, governmental restrictions and/or regulations; terrorism or apparent act of terrorism; extreme weather; disaster; civil disorder, disturbance, and/or riots; curtailment, suspension, and/or restriction on transportation facilities/means of transportation; unavailability of the teacher due to ill-health or any other emergency.
            </p>

            <h4>Cancellation where there is a failure in delivery of a module lesson:</h4>
            <p>
            Under the Australian Consumer Law, the module lessons offered by AcLangEDGE come with the guarantee that they will be:
              <ul>
                <li>provided with acceptable care and skill or technical knowledge and taking all necessary steps to avoid loss and damage; </li>
                <li>fit for the purpose or provide the services that AcLangEDGE and you agreed to; and</li>
                <li>delivered within a reasonable time frame of the scheduled module lesson time/s.</li>
              </ul>
            </p>
            <p>
            AcLangEDGE is legally obliged to provide a full refund if requested, where there is a ‘major failure’ of one or more of the guarantees. A 'major failure' occurs where the service: 
              <ul>
                <li>has a problem that would have stopped you from buying it if they had known about it; or </li>
                <li>is substantially unfit for its purposes and cannot easily be fixed with a reasonable time; or </li>
                <li>does not meet the specific purpose that it was intended for and cannot easily be fixed within a reasonable time period.</li>
              </ul>
            </p>
            <p>
            If the failure that has occurred is not a major failure but is still a failure on the part of AcLangEDGE, AcLangEDGE is required to rectify the failure. This might involve, but is not limited to, rescheduling a module lesson, or part of a module lesson.
            </p>

            <h4>Procedures for credit or refund request</h4>
            <p>
            Should you wish to request a credit or refund, please contact AcLangEDGE at aclangedge@gmail.com
            </p>

            <h3 className="pt-5">Section C: AcLangEDGE Privacy Policy</h3>
            <p>
              AcLangEDGE is Committed to Protecting Your Privacy.
            </p>
            <p>
            AcLangEDGE takes all reasonable steps to implement processes and procedures to safeguard Personal Information. AcLangEDGE endeavours to but is not required to comply with the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) (Law). While not claiming to be a statement of compliance, this Privacy Policy outlines AcLangEDGE’s use, collection and storage of Personal Information. This Privacy Policy applies to all Personal Information collected by AcLangEDGE in the course of providing services to users (Users) regardless of the source of that Personal Information.  This Privacy Policy is available on the Site. This Privacy Policy should be read in conjunction with AcLangEDGE's Terms and Conditions when using Student Resources and interacting with AcLangEDGE.
            </p>

            <h4>1. Definitions</h4>
            <p>
            Terms that are capitalised in this Privacy Policy are defined below, unless the context indicates otherwise.
              <ul>
                <li>Site means www………</li>
                <li>Minor means a person under the age of eighteen (18).</li>
                <li>Personal Information means information or an opinion about an identified individual, or an individual who is reasonably identifiable:
                  <ul>
                    <li>whether the information or opinion is true or not; and</li>
                    <li>whether the information or opinion is recorded in a material form or not.</li>
                  </ul>
                </li>
                <li>Privacy Policy means this privacy policy and amendments made to it.</li>
              </ul>
            </p>

            <h4>2. Obligations under the Law</h4>
            <h5>2.1 Collection of Personal Information</h5>
            <p>
            AcLangEDGE collects Personal Information about Users in order to provide the Users with the services and goods they have requested.  If this information is not provided AcLangEDGE may not be able to provide Users with requested services or goods. AcLangEDGE endeavours to collect Personal Information from Users directly.  Information AcLangEDGE obtains from third parties or publicly available information is limited to circumstances where the User has consented whether expressly or impliedly.
            </p>

            <h5>2.2 Types of Personal Information collected</h5>
            <p>
            Personal Information AcLangEDGE collects may include a person’s first and last names and email address and any other Personal Information supplied by the User.  If a User connects AcLangEDGE to a third party social media service, AcLangEDGE may collect additional Personal Information that includes social media profile handles, user ID and/or user names associated with that service and any information or content the User has permitted the social media service to share with AcLangEDGE, which may include the User’s profile picture, email address, friends or follower lists, and information the User has disclosed in connection with that social media services.
            </p>

            <h5>2.3 Sensitive information</h5>
            <p>
            AcLangEDGE does not collect sensitive information about Users.  Sensitive information includes information about racial or ethnic origin; or political opinions; or membership of a political association; or religious beliefs or affiliations; or philosophical beliefs; or membership of a professional or trade association; or membership of a trade union; or sexual orientation or practices; or criminal record; that is also personal information; or health information about an individual; or genetic information about an individual that is not otherwise health information; or biometric information that is to be used for the purpose of automated biometric verification or biometric identification; or biometric templates.
            </p>

            <h5>2.4 Purpose of collection and use</h5>
            <p>
            AcLangEDGE collects, holds, uses and discloses Personal Information to deliver and improve the goods or services AcLangEDGE provides to Users.  Generally, Users are only obliged to provide AcLangEDGE with information necessary for AcLangEDGE to provide services to the User.  However, if Users do not provide AcLangEDGE with certain types of Personal Information AcLangEDGE may be unable to provide the User the services or goods they have requested.
            </p>

            <h5>2.5 Collection of information from the Internet</h5>
            <p>
            AcLangEDGE collects information from the Site and from AcLangEDGE’s interactions with the Users.  This information is all held in digital format only. Reasonable steps are taken to ensure all information provided is secure. AcLangEDGE will collect Personal Information by only lawful and fair means.  The Law requires AcLangEDGE to collect Personal Information about Users from the Users directly, if it is reasonable and practical to do so.
            </p>

            <h4>3. Access, Correction and Complain Procedure</h4>
            <h5>3.1 Accessing Personal Information about you</h5>
            <p>
            If a User would like to access or correct Personal Information that AcLangEDGE holds about them, please contact: <br></br>
            The AcLangEDGE Privacy Officer <br></br>
            Timothy Hudson <br></br>
            ABN: 95975859033 <br></br>
            Unit 21101, 63 Blamey Street, Kelvin Grove, QLD 4059, Australia<br></br>
            Telephone: +61 406081150<br></br>
            Email: aclangedge@gmail.com <br></br>
            </p>
            <p>
            The User will need to identify themselves to the reasonable satisfaction of AcLangEDGE before AcLangEDGE will provide the User with their Personal Information which AcLangEDGE may or may not have in its possession.
            </p>

            <h5>3.2 Complaints procedure</h5>
            <p>
            AcLangEDGE operates a service orientated business via the Site.  If a User has a complaint about AcLangEDGE’s collection or use of Personal Information pertaining to that User, then the User is requested to contact AcLangEDGE’s Privacy Officer at first instance. If after investigating the User’s complaint and reporting to the User about an alleged breach of the Australian Privacy Principles and reporting the results of AcLangEDGE's investigations to the User, the User is not satisfied, then AcLangEDGE requests that the User consult: <br></br>
            The Australian Information Privacy Commissioner <br></br>
            GPO Box 5218 <br></br>
            Sydney NSW 2001 <br></br>
            Telephone: 1300 363 992 <br></br>
            Email: enquiries@oaic.gov.au <br></br>
            A complaint form can be found at: https://forms.business.gov.au/aba/oaic/privacy-complaint-/
            </p>

            <h5>3.3 Transfer of Personal Information outside Australia</h5>
            <p>
            Personal Information AcLangEDGE collects may in the future be accessed by employees and contractors of service providers outside of Australia.  Personal Information may be transferred to countries outside Australia. AcLangEDGE takes reasonable steps to ensure that parties that provide AcLangEDGE with necessary services for website hosting and database administration act in accordance with the Australian Privacy Principles and have signed undertakings for their staff relating to confidentiality. AcLangEDGE also uses only recognised service providers who use enterprise level software with up to date encryption.
            </p>

            <h5>3.4 Anonymity and use of pseudonyms</h5>
            <p>
            Where practical and reasonable to do so, AcLangEDGE provides Users with the opportunity to use pseudonyms in relation to information Users provide to AcLangEDGE.  Where appropriate AcLangEDGE will provide Users with the option to interact anonymously.
            </p>

            <h5>3.5 How AcLangEDGE deals with unsolicited Personal Information</h5>
            <p>
            AcLangEDGE only collects Personal Information about individuals by lawful and fair means.
            </p>
            
            <h5>3.6 Notice of collection of Personal Information</h5>
            <p>
            Where possible, AcLangEDGE notifies the Users when AcLangEDGE is collecting Personal Information about the Users, use of which is subject to this Privacy Policy.
            </p>

            <h5>3.7 Collection Notice</h5>
            <p>
            By providing AcLangEDGE with this information, the User acknowledges that AcLangEDGE is collecting and storing Personal Information about the User. AcLangEDGE does this only for the purpose of providing goods or services to the User.
            </p>

            <h4>4. Use of Personal Information in Direct Marketing</h4>
            <h5>4.1 Direct marketing</h5>
            <p>
            AcLangEDGE may use Personal Information for the purposes of promoting goods or services to Users where they would reasonably expect it to be used for this purpose.  If AcLangEDGE uses a User’s Personal Information to provide them with promotional and marketing information AcLangEDGE will provide the User with an option to opt out of this service. AcLangEDGE does not provide goods that contain advertising targeted towards Minors.
            </p>

            <h5>4.2 Use of Cookies</h5>
            <p>
            AcLang collects Personal Information that is reasonably necessary for the performance of AcLangEDGE's operations and activities including marketing and sales. AcLangEDGE automatically receives and records information on its server logs from the User’s browser, including their IP address, operating system, top level domain, date and time, pages accessed, documents downloaded, previous website visited, type of browser used and other cookie information.  This enables AcLangEDGE to tell when Users use the Site and also to help customise the User’s experience within the Site.  No Personal Information about a User is linked to the cookie nor is there any data matching between cookie information and User’s internet activity.
            </p>

            <h4>5. Integrity of Personal Information</h4>
            <h5>5.1 Security measures taken for Personal Information</h5>
            <p>
            AcLangEDGE takes reasonable steps to protect the Personal Information AcLangEDGE holds from misuse and loss and from unauthorised access, modification or disclosure when AcLangEDGE sends Personal Information offshore.  Further, AcLangEDGE takes reasonable steps to destroy or permanently de-identify Personal Information if it is no longer needed for the purpose it was collected.
            </p>

            <h5>5.2 Accuracy</h5>
            <p>
            AcLangEDGE takes reasonable steps to ensure that the Personal Information AcLangEDGE collects, uses or discloses is accurate, complete and up-to-date.
            </p>

            <h5>5.3 Security</h5>
            <p>
            AcLangEDGE takes reasonable steps to safeguard the disclosure of Personal Information from third parties, contractors and staff. AcLangEDGE has up to date procedures and policies relating to information technology as it relates to staff and strive for best practice in this area.
            </p>

            <h4>6. Changes to Privacy Policy</h4>
            <p>
              This Privacy Policy may change from time to time.
            </p>
        </Container>
    );
};

export default TermsAndConditionsScreen;