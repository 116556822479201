import React, {useEffect} from 'react';
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import bioPhoto from '../../resources/tim.jpg';

const PDScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const isMobileDevice = /Mobi/i.test(navigator.userAgent);

    return (
        <Container className="pt-5">
            <h2>AcLangEDGE Vocabulary to Discourse Courses</h2>
            <h5 className="pt-2 fst-italic">Practical Professional Development (PD) for Teachers of Multilingual Learners (MLL)</h5>

            <h4 className="pt-5">Rationale</h4>
            <hr/>

            <p>
            In the ever growing number of schools with multilingual student populations, classroom teachers have the desire to deliver 
            their subject in order to effectively and consistently support the academic development of their multilingual learners across the 
            curriculum. Effective, practical, consistent and cyclical professional development of all teachers and school curriculum/pedagogy
            managers in this growing area is vital to promoting inclusion and equity for MLL enrolled at your school.
            </p>

            <h4 className="pt-5">Microlearning</h4>
            <hr/>

            <p>
            Schools have become very busy workplaces for teachers and managers alike, and it has been demonstrated that <span className="fw-bold">online asynchronous 
            microlearning</span> has a much higher uptake level among teachers, compared with the more traditional longer face to face PD sessions on ‘staff only’ days. 
            </p>

            <p>
            Online asynchronous microlearning is a way of delivering professional development to teachers in bite-sized (15-30 minutes) segments, 
            accessible at the point of need, with focused and specific learning outcomes. The teacher (or subject department) is in control of what and 
            when they engage in this style of professional development. Moreover, this type of practical, bite-sized training is far more likely to be 
            transferred into teachers’ actual classroom practice.
            </p>

            <p>
            In the modern lives of very busy teachers, it is crucial that teachers are able to complete their 
            professional development learning at a time and place that suits their busy schedule.
            </p>

            <h4 className="pt-5">What are the AcLangEDGE Courses?</h4>
            <hr/>

            <p>
            The three <span className="fst-italic">AcLangEDGE Vocabulary to Discourse</span> online courses include a total of 24 discrete microlearning modules (micro-modules). 
            These micro-modules are by design practical, providing teachers with takeaways and templates to use when supporting multilingual learners of 
            various target language proficiencies in the classroom. The micro-modules can be accessed asynchronously on demand by your school’s 
            individual teachers, or by departments to utilise collaboratively during team meetings. Where appropriate, micro-modules are accompanied 
            with templates for teachers adaptation and use in their subject’s context.
            </p>

            <ol>
                <li><span className="fw-bold fst-italic">Course Title : Academic Vocabulary Acquisition</span>
                    <ul>
                        <li>1A : AcLangEDGE Five Pillars (Planning for Vocabulary Acquisition)</li>
                        <li>1B : Classifying Academic Vocabulary</li>
                        <li>1C : Defining, Identifying and Classifying Collocations</li>
                        <li>1D : Joining Quizlet and Creating a Quizlet Set</li>
                        <li>1E : Creating and Sharing a Quizlet Class - Benefits of QuizletPlus</li>
                        <li>1F : Quizlet Tips - In the Classroom and at Home</li>
                        <li>1G : Using Quizlet LIVE and TEST</li>
                        <li>1H : Academic Vocabulary Recycling Strategies</li>
                    </ul>
                </li>
                
                <li><span className="fw-bold fst-italic">Course Title : Developing MLL Academic Language Comprehension Skills</span>
                    <ul>
                        <li>2A : Promoting Active Listening and Viewing - EdPuzzle</li>
                        <li>2B : Teaching Questioning Techniques</li>
                        <li>2C : Dictogloss - Differentiation Strategies</li>
                        <li>2D : Pre-Reading Routines for the Classroom</li>
                        <li>2E : Using ChatGPT to Differentiate Reading Tasks</li>
                        <li>2F : Jigsaw Reading Strategies</li>
                        <li>2G : Developing Effective Cloze Activities</li>
                        <li>2H : Intensive and Extensive Reading - Graded Reading Programs</li>
                    </ul>
                </li>

                <li><span className="fw-bold fst-italic">Course Title : Developing MLL Academic Language Production Skills</span>
                    <ul>
                        <li>3A : Text Types - Features and Scaffolds</li>
                        <li>3B : Teaching Paragraph Writing Across the Curriculum</li>
                        <li>3C : The Important Role of Students' L1 in L2 Acquisition</li>
                        <li>3D : Language Production Error Treatment and Error Coding Strategies</li>
                        <li>3E : Command Terms and Supporting Sentence Frames</li>
                        <li>3F : Research Task 'Scaffolding Up' with Embedded Translanguaging</li>
                        <li>3G : Understanding the 'Silent Period', Wait Time and Cooperative Learning Basics</li>
                        <li>3H : 'Think-Pair-Share' and Inclusive Questioning Techniques</li>
                    </ul>
                </li>
            </ol>

            {( !isMobileDevice ) && (
                <>
                <h4 className="pt-5">Course Preview</h4>
                <hr/>

                <p>Below is a preview for one of the 24 modules in the course.</p>
                <Accordion className="pt-2">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Teaching Questioning Techniques</Accordion.Header>
                        <Accordion.Body>
                            <iframe title="prev-slides" src="https://docs.google.com/presentation/d/e/2PACX-1vSM9jYwyHBc6CUEti1DKAt5k3S1z2XDvhgcaEhLyfHlgwPCAbcy1CgOEFMmfQ1tBPFuqS6gyGt5_Ql3/embed?start=false&loop=false&delayms=3000" width="100%" height="880" allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                            <iframe title="prev-doc" src="https://docs.google.com/document/d/e/2PACX-1vRptm32ABW9PyWks8E4pjLmM8_tcsTfwCjbAYoRrLxiajaJnI_jgYxZLNaHFv-_DeMmY9icHHQqWzUr/pub?embedded=true" width="100%" height="800"></iframe>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </>
            )}

            <h4 className="pt-5">School Campus OR Organisation Once-Off Subscription</h4>
            <hr/>
            <ul>
                <li>
                    Once-off subscription fee includes unlimited and <span className="fw-bold">ongoing</span> access to all <span className="fst-italic">AcLangEDGE Vocabulary to Discourse Teacher Courses</span> microlearning modules for the subscribed school/organisation`s teaching staff;
                </li>
                <li>
                    Subscribing schools/organisations will receive the <span className="fw-bold">PDF copy of course presenter/participant notes</span>, which can be shared and used by all presenters and participants within the subscribed organisation.
                </li>
            </ul>

            <h5 className="pt-5"><span className="fw-bold">To subscribe </span> - Click <Link to="/org-register">here</Link>.</h5>

            <p className="pt-5"><span className="fw-bold">For an invoice, or further enquiries prior to or post purchase</span>, please email: <u>aclangedge@gmail.com</u></p>
            
            <h4 className="pt-5">EAL School's Consultant</h4>
            <hr/>
            <Row>
            <Col xs={12} md={4}>
            <Image src={bioPhoto} roundedCircle fluid />
            </Col>
            <Col xs={12} md={8}>
                <div className="pt-5">
                    Tim has worked in both international and Australian schools for 33 years. After returning to Queensland from a seven year stint (1990-1997) at Shanghai American School in various Head of Department roles and involvement in WASC Accreditation Visitation Teams, from 2002-2005, Tim chaired the Education Hervey Bay group which included local government and non-government schools, Wide Bay TAFE, University of Southern Queensland, along with the Hervey Bay City Council, and was tasked with making the region a desired and popular destination for international students. 
                    Simultaneously he was the founding Director of International Student Programs at Fraser Coast Anglican College, developing the international program from it's inception and initial CRICOS Registration in 1999 to the thriving and sustainable international programs the College has in place today, which include regular short term study tours, student exchanges and long full fee paying overseas students. 
                    <br/><br/>He then worked at the Australian International School in Singapore for 17 years as the Head of EAL, during which time the school's EAL student population grew from 50 to 400 students, of a total school population of approximately 2400. This EAL student growth contributed an average annual revenue of $4 million for the school across his 17 year tenure. The EAL programs developed by Tim at AIS Singapore remain world leading, including a mixture of sheltered English and collaboratively co-taught subject classes in the middle years, and appropriate IB Diploma courses in the senior years, 
                    ensuring EAL student academic and social integration into the school population. At AIS EAL students graduated from the programs into English First Language classes  at a rate of 10-20% per year, due to the effective systems, curriculum and monitoring developed by Tim. During his time in Singapore, on several occasions Tim provided professional development sessions for Singapore Ministry of Education (MoE)  local primary and secondary school principals on structuring appropriate programs for EAL learners in English medium of instruction schools, as Singapore also opened up to this market.
                    <br/><br/>If your school is new to the international/EAL student market, if your school has an existing EAL student population and current EAL structures in place need maintenance due to rapid growth or inappropriate systems, or if your school's teachers would benefit from practical professional development that is proven to be effective for teaching and learning in real classrooms containing any number of EAL learners, contact Tim to see how he can help your school maximise the academic performance of your EAL student population and your place in an increasingly competitive international student market.  
                </div>
            </Col>
            </Row>
        </Container>
    );
};

export default PDScreen;