import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";

const OtherPoliciesScreen = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])  

    return (
        <Container className="pt-5">
          <h2>AcLangEDGE Refund Policy</h2>
          <hr/>

          <p className="pt-5">
          The Australian Consumer Law sets out consumer rights that are called consumer guarantees. These include your rights to a replacement or refund where a product or service you buy fails to meet our consumer guarantee. This policy applies to the refund of online academic language lesson fees and accompanying resources that have been paid by students/parents (‘You’ or ‘you’) to AcLangEDGE.
          </p>

          <h4>Academic Language Lesson Fees:</h4>
          <p>
          AcLangEDGE offers pre-paid individual lesson bundles (an agreed bundle of one hour lessons), and the bundle must be purchased up front via the AcLangEDGE website after initial free consultation meeting. Pre-paid lessons and bundles are not refundable other than the conditions outlined under this Policy.
          </p>

          <h4>AcLangEDGE offers one type of educational service:</h4>
          <p>
            <ul>
              <li>One-to-one academic language courses </li>
              <li>(3-5 x 1 hour online lesson per week) for time periods from 5 to 10 weeks</li>
            </ul>
          </p>
          <p>If you cancel a scheduled lesson:
            <ul>
              <li>More than 24 hours before the scheduled lesson, you can reschedule your lesson for a different time prior to your next lesson.</li>
              <li>Less than 24 hours before the scheduled lesson or if you fail to arrive for a lesson, you will not be entitled to a refund for that lesson or a rescheduled lesson.</li>
            </ul>
          </p>

          <h4>Cancellation Where There is a Failure in Delivery of a Lesson:</h4>
          <p>
          Under the Australian Consumer Law, the online  lessons and Student Resources offered by AcLangEDGE come with the guarantee that they will be:
            <ul>
              <li>provided with acceptable care and skill or technical knowledge and taking all necessary steps to avoid loss of student work;</li>
              <li>fit for the purpose or provide the services that AcLangEDGE and you agreed to; and</li>
              <li>delivered within the stated time frame of the scheduled online lessons.</li>
            </ul>
          </p>

          <p>
          AcLangEDGE is legally obliged to provide a full refund if requested, where there is a ‘major failure’ of one or more of the guarantees. A major failure occurs where the AcLangEDGE service:
            <ul>
              <li>has a problem that would have stopped you from buying it if they had known about it; or</li>
              <li>is substantially unfit for its purposes and cannot easily be fixed with a reasonable time; or</li>
              <li>does not meet the specific purpose that it was intended for and cannot easily be fixed within a reasonable time period.</li>
            </ul>
          </p>

          <p>
          If the failure that has occurred is not a major failure but is still a failure on the part of AcLangEDGE, AcLangEDGE is required to rectify the failure. This might involve, but is not limited to, rescheduling an online lesson, or part of an online lesson.
          </p>

          <h4>Procedures for a Refund Request:</h4>
          <p>
          Should you wish to request a refund, please contact AcLangEDGE at: aclangedge@gmail.com
          </p>

          <h2 className="pt-5">AcLangEDGE Photography Policy</h2>
          <p>
            At AcLangEDGE, we take the privacy and safety of all our students very seriously, especially when it comes to social media sites and other public platforms. We 
            understand that some families for a wide variety of reasons do not want their children's images to be placed on social media sites or used in a way the 
            parent has no control over. This is a acknowledged child safety issue, and we will do our best to ensure that all children in our care are always as safe as possible,
            and that their privacy is protected. We also acknowledge that AcLangEDGE staff should also have their privacy protected from unauthorized recordings made by students and 
            parents.
          </p>
        </Container>
    );
};

export default OtherPoliciesScreen;