// Constants.js
const prod = {
    baseUrl: "https://www.aclangedge.com.au",
    apiBaseUrl: 'https://api.aclangedge.com.au/api',
    stripePublishableKey: 'pk_live_51MVwkIE1kp0CmO2iYOdrH4SVio2OVBVBUwQY2GwiOovhC9EGB4Sknp8PzjOQdIFjUimRKsfdczqDXXmVGNv6MrNA00vdsZcODs'
};
const dev = {
    baseUrl: "http://localhost:3000",
    apiBaseUrl: 'http://localhost:3001/api',
    stripePublishableKey: 'pk_test_51MVwkIE1kp0CmO2ipbSfkMjsDiCSsowPkQJUAvbl9yewPCFfGuMfGYiUveY5k6Ta73au9JokedUSCUYwK3unKgxY00NT30Bg0T'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;