import React, {useEffect} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import bioPhoto from '../../resources/tim.jpg';

const BioScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container className="pt-5">
      <Row>
        <Col xs={12} md={4}>
          <Image src={bioPhoto} roundedCircle fluid />
        </Col>
        <Col xs={12} md={8}>
          <h2>Mr Tim Hudson</h2>

          <h4 className="pt-3">Teaching Experience</h4>

          <p>
            <span className="fw-bold">2006-2022</span> (17 years): Australian International School, Singapore  - Head of EAL Department<br></br>
            <span className="fw-bold">1997-2005</span> (8 years): Fraser Coast Anglican College - International Student Coordinator<br></br>
            <span className="fw-bold">1992-1997</span> (5 years): Shanghai American School, P.R. China - Head of Math and Languages Department
          </p>

          <h4 className="pt-3">Specialised Teaching Skills</h4>
          
          <p>
            <ul>
              <li>Mathematics, Science, Humanities, Commerce, English and EAL classroom teacher (consistently rated at LEAD level on Australian Institute for Teaching and School Leadership - AITSL)</li>
              <li>Nominated for 'Global Cognita Schools Teacher of the Year' award in 2014</li>
              <li>IB (International Baccalaureate), IGCSE, Australian and US curriculum teaching and leadership experience and expertise</li>
              <li>Curriculum writer & academic language program development</li>
              <li>Head of various subject departments & WIDA academic language level assessor</li>
              <li>Chair of Education Hervey Bay group 2004-2005</li>
              <li>Online teaching expert</li>
              <li>Approaching Bilingual : English/Chinese (Mandarin)</li>
              <li>Member of WASC accreditation team for international schools in Hong Kong</li>
              <li>Professional development provider for Singapore Ministry of Education (MoE) for various local school principals on structuring appropriate programs for EAL learners in English medium of instruction schools</li>
            </ul>
          </p>
          
          <h4 className="pt-3">Qualifications</h4>

          <p>
            <ul>
              <li>Diploma of Teaching (Secondary Mathematics and Humanities)</li>
              <li>Graduate Diploma of Applied Linguistics (ESL and Chinese)</li>
            </ul>
          </p>

          <h4 className="pt-3">Current Registrations</h4>

          <p>
            <ul>
              <li>Queensland College of Teachers</li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default BioScreen;