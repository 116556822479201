import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-light pt-5 mt-5">
      <Container className="footer-container">
        <Row>
          <Col md={6} xs={12}>
            <div className="d-flex flex-column pt-2">
              <div className="d-flex align-items-center mb-3">
                <FaMapMarkerAlt className="mr-2" />
                <div>
                  <p className="mb-0 ps-2">Queensland, Australia</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaPhone className="mr-2" />
                <p className="mb-0 ps-2">+61 406 081 150</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaEnvelope className="mr-2" />
                <p className="mb-0 ps-2">aclangedge@gmail.com</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p className="mb-0 ps-2">&copy; Tim Hudson 2024</p>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Nav>
              <Nav.Item>
                <Nav.Link href="terms-and-conditions">Terms and Conditions</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="other-policies">Other Policies</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        
      </Container>
    </footer>
  );
};

export default Footer;