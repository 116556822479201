import { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import instance from "../../../services/api";

const UserAccountScreen = () => {
  const [fullName, setFullName] = useState("");
  const [group, setGroup] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      await instance.get("/user").then(
        (response) => {
          setFullName(response.data.fullName);
          setGroup(response.data.group);
        }
      );
    }

    fetchData();
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();

    await instance.post("/user", { fullName, group })
      .then(() => {
        window.alert("Successfully updated details.");
      })
      .catch(() => {
        window.alert("Failed to update details.");
      });
  };
  
  return (
    <Container className="mt-5">
      <Form onSubmit={handleSave}>
        <h3>My Account</h3>
        <hr className="mb-5"/>
        <Form.Group className="mb-3" controlId="formFullName">
          <Form.Label>Full Name:</Form.Label>
          <Form.Control 
            value={fullName} 
            name="fullName"
            onChange={(e) => setFullName(e.target.value)} 
            tabIndex={1}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroup">
          <Form.Label>Group:</Form.Label>
          <Form.Select value={group} name="group" onChange={(e) => setGroup(e.target.value)}>
            <option value="Agent">Agent</option>
            <option value="Student">Student</option>
            <option value="School">School</option>
          </Form.Select>
        </Form.Group>
        <Button variant="primary" type="submit" tabIndex={2}>
          Save Changes
        </Button>
      </Form>
    </Container>
  );
};

export default UserAccountScreen;
