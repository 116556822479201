import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

// Screens
import HomeScreen from "./components/screens/HomeScreen";
import AboutScreen from "./components/screens/AboutScreen";
import BioScreen from "./components/screens/BioScreen";
import PDScreen from "./components/screens/PDScreen";
import AgentScreen from "./components/screens/AgentScreen";
import LoginScreen from "./components/screens/LoginScreen";
import OrgLoginScreen from "./components/screens/OrgLoginScreen";
import OrgRegisterScreen from "./components/screens/OrgRegisterScreen";
import RegisterScreen from "./components/screens/RegisterScreen";
import TestimonialsScreen from "./components/screens/TestimonialsScreen";
import FAQScreen from "./components/screens/FAQScreen";
import FreeConsultationScreen from "./components/screens/FreeConsultationScreen";
import OtherPoliciesScreen from "./components/screens/other/OtherPoliciesScreen";
import TermsAndConditionsScreen from "./components/screens/other/TermsAndConditionsScreen";
import OrgTermsAndConditionsScreen from "./components/screens/other/OrgTermsAndConditionsScreen";
import VerifyEmailScreen from "./components/screens/other/VerifyEmailScreen";
import OrgVerifyEmailScreen from "./components/screens/other/OrgVerifyEmailScreen";

import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen";
import OrgForgotPasswordScreen from "./components/screens/OrgForgotPasswordScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import OrgResetPasswordScreen from "./components/screens/OrgResetPasswordScreen";

import UserAccountScreen from "./components/screens/user/UserAccountScreen";
import UserModulesScreen from "./components/screens/user/UserModulesScreen";
import UserBookingsScreen from "./components/screens/user/UserBookingsScreen";
import UserLessonsScreen from "./components/screens/user/UserLessonsScreen";

import CoursesScreen from "./components/screens/org/CoursesScreen";

import AdminScreen from "./components/screens/admin/AdminScreen";

import CheckoutScreen from "./components/screens/stripe/CheckoutScreen";
import OrgCheckoutScreen from "./components/screens/stripe/OrgCheckoutScreen";

import { config } from "./components/common/Constants";

import instance from "./services/api";
import axios from "axios";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOrg, setIsOrg] = useState(false);
  const navigate = useNavigate();

  const login = async ( email, password ) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post("/auth/login", { email, password }, config)
      .catch(() => {
        alert("Invalid credentials");
      });
    
    if ( !response ) {
      return;
    }

    if ( response.data !== null && response.data.jwtToken ) {
      localStorage.setItem("authToken", response.data.jwtToken);
      navigate("/");

      setIsLoggedIn(true);
      setIsOrg(false);
      setIsAdmin(response.data.isAdmin);
    };
  };

  const orgLogin = async ( username, password ) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post("/auth/org-login", { username, password }, config)
      .catch(() => {
        alert("Invalid credentials");
      });
    
    if ( !response ) {
      return;
    }

    if ( response.data !== null && response.data.jwtToken ) {
      localStorage.setItem("authToken", response.data.jwtToken);
      navigate("/");

      setIsLoggedIn(true);
      setIsOrg(true);
      setIsAdmin(false);
    };
  };

  const logout = () => {
    navigate("/");
    localStorage.clear();
    setIsLoggedIn(false);
    setIsOrg(false);
    setIsAdmin(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken');

      if ( token ) {
        const response = await instance.get("/auth/verify");

        if ( !response ) {
          setIsLoading(false);
          return;
        }

        if ( response.data.valid ) {
          setIsLoggedIn(true);
          setIsOrg(response.data.org);

          if ( !response.data.org ) {
            setIsAdmin(response.data.isAdmin);
          } else {
            setIsAdmin(false);
          }
        } else {
          setIsLoggedIn(false);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchData();

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  axios.defaults.baseURL = config.apiBaseUrl;

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        includedLanguages: 'en,zh-CN,ko,vi,it,ja',
      },
      "google_translate_element"
    );
  };

  const PrivateRoute = ({ children }) => {
    return isLoggedIn && !isOrg ? children : null;
  };

  const OrgRoute = ({ children }) => {
    return ( isLoggedIn && ( isOrg || isAdmin ) ) ? children : null;
  };

  const AdminRoute = ({ children }) => {
    return ( isLoggedIn && isAdmin ) ? children : null;
  };

  if ( isLoading ) {
    return <p></p>;
  }
  
  return (
      <div>
      <Header isLoggedIn={isLoggedIn} isAdmin={isAdmin} isOrg={isOrg} logout={logout} />
      <div className="mx-5" align="end" id="google_translate_element"></div>
      <main className="flex-grow-1">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/bio" element={<BioScreen />} />
        <Route path="/professional-development" element={<PDScreen />} />
        <Route path="/agents" element={<AgentScreen />} />
        <Route path="/testimonials" element={<TestimonialsScreen />} />
        <Route path="/faq" element={<FAQScreen />} />
        <Route path="/free-consultation" element={<FreeConsultationScreen />} />

        <Route path="/login" element={<LoginScreen login={login} isLoggedIn={isLoggedIn}/>}/>
        <Route path="/org-login" element={<OrgLoginScreen login={orgLogin} isLoggedIn={isLoggedIn}/>}/>
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/org-forgot-password" element={<OrgForgotPasswordScreen />} />
        <Route path="/reset-password/:resetToken" element={<ResetPasswordScreen />} />
        <Route path="/org-reset-password/:resetToken" element={<OrgResetPasswordScreen />} />

        <Route path="/other-policies" element={<OtherPoliciesScreen />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />} />
        <Route path="/org-terms-and-conditions" element={<OrgTermsAndConditionsScreen />} />
        <Route path="/verify-email" element={<VerifyEmailScreen />} />
        <Route path="/org-verify-email" element={<OrgVerifyEmailScreen />} />

        <Route path="/org-register" element={<OrgRegisterScreen />} />
        <Route path="/org-checkout" element={<OrgCheckoutScreen />} />

        <Route path="/my-account" element={
          <PrivateRoute>
            <UserAccountScreen />
          </PrivateRoute>
        }/>
        <Route path="/my-modules" element={
          <PrivateRoute>
            <UserModulesScreen />
          </PrivateRoute>
        }/>
        <Route path="/my-bookings" element={
          <PrivateRoute>
            <UserBookingsScreen />
          </PrivateRoute>
        }/>
        <Route path="/my-lessons" element={
          <PrivateRoute>
            <UserLessonsScreen />
          </PrivateRoute>
        }/>
        <Route path="/checkout" element={
          <PrivateRoute>
            <CheckoutScreen />
          </PrivateRoute>
        } />
        
        <Route path="/admin" element={
          <AdminRoute>
            <AdminScreen />
          </AdminRoute>
        } />

        <Route path="/org-courses" element={ 
          <OrgRoute>
            <CoursesScreen />
          </OrgRoute>
        } />
      </Routes>
      </main>
      <Footer/>
      </div>
  );
};

export default App;
